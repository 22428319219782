import React, { useState, useEffect } from 'react';
import './i18n';
import Router from './routes';
import { useRefreshTokenMutation } from './services/authService';
import { useDispatch } from 'react-redux';
import LoadingSpinner from './components/LoadingSpinner';
import { useTranslation } from 'react-i18next';

function App() {
  const dispatch = useDispatch();
  const { i18n } = useTranslation();
  const [refreshToken] = useRefreshTokenMutation();
  const [isReady, setIsReady] = useState(false);

  useEffect(() => {
    refreshToken()
      .unwrap()
      .then(res => {
        i18n.changeLanguage(res?.user?.lang_code || 'en');
        setIsReady(true);
      })
      .catch(() => {
        dispatch({ type: 'auth/logout' });
        setIsReady(true);
      });
  }, [dispatch, refreshToken]);

  if (!isReady) {
    return <LoadingSpinner />;
  }

  return <Router />;
}

export default App;