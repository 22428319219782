import React from 'react';
import { useTranslation } from 'react-i18next';

function Navigation() {
  const { t } = useTranslation();

  return (
    <nav className="bg-white shadow-md sticky top-0 z-10">
      <div className="container mx-auto px-4">
        <ul className="flex flex-wrap justify-center space-x-4 sm:space-x-8 py-4">
          <li><a href="#intro" className="text-gray-600 hover:text-indigo-600 transition duration-300">{t('home.nav.intro')}</a></li>
          <li><a href="#benefits" className="text-gray-600 hover:text-indigo-600 transition duration-300">{t('home.nav.benefits')}</a></li>
          <li><a href="#reviews" className="text-gray-600 hover:text-indigo-600 transition duration-300">{t('home.nav.reviews')}</a></li>
          <li><a href="#pricing" className="text-gray-600 hover:text-indigo-600 transition duration-300">{t('home.nav.pricing')}</a></li>
          <li><a href="#download" className="text-gray-600 hover:text-indigo-600 transition duration-300">{t('home.nav.download')}</a></li>
        </ul>
      </div>
    </nav>
  );
}

export default Navigation;