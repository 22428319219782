import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { Link, useNavigate } from 'react-router-dom';
import { Eye, EyeOff } from 'lucide-react';
import Layout from '@components/account/Layout';
import { useTranslation } from 'react-i18next';
import { useSignupMutation } from '../../services/authService';
import { setCredentials } from '@store/slice/authSlice';
import { useDispatch } from 'react-redux';
import Alert from '../../components/input/Alert';

function SignUp() {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  const [signup, { isLoading, isError, error }] = useSignupMutation();

  const validationSchema = Yup.object().shape({
    name: Yup.string().required(t('account.form.error.required')),
    email: Yup.string().email(t('account.form.error.email')).required(t('account.form.error.required')),
    password: Yup.string().min(8, t('account.form.error.passwordMin')).required(t('account.form.error.required')),
  });

  const { register, handleSubmit, formState: { errors } } = useForm({
    resolver: yupResolver(validationSchema),
  });
  const [showPassword, setShowPassword] = useState(false);

  const onSubmit = async (data) => {
    try {
      const result = await signup({
        lang_code: i18n.language,
        name: data.name,
        email: data.email,
        password: data.password,
      }).unwrap();

      navigate('/verify-email', { replace: true });
    } catch (err) {
      console.error('Failed to sign up:', err);
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <Layout>
      <div className="w-full max-w-md">
        <div className="bg-white rounded-lg shadow-md overflow-hidden p-6 mb-8">
          <h2 className="text-2xl font-bold mb-6 text-center">{t('account.signup.title')}</h2>
          
          {isError && (
            <Alert className="mb-4">
              {error?.data?.message || t('account.signup.genericError')}
            </Alert>
          )}
          
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="mb-4">
              <label htmlFor="signup-name" className="block text-gray-700 font-bold mb-2">{t('account.form.name')}</label>
              <input
                type="text"
                id="signup-name"
                {...register('name')}
                className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:border-indigo-500"
              />
              {errors.name && <Alert>{t(`account.form.error.${errors.name.type}`, { field: t('account.form.name') })}</Alert>}
            </div>
            <div className="mb-4">
              <label htmlFor="signup-email" className="block text-gray-700 font-bold mb-2">{t('account.form.email')}</label>
              <input
                type="email"
                id="signup-email"
                {...register('email')}
                className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:border-indigo-500"
              />
              {errors.email && <Alert>{t(`account.form.error.${errors.email.type}`, { field: t('account.form.email') })}</Alert>}
            </div>
            <div className="mb-4">
              <label htmlFor="signup-password" className="block text-gray-700 font-bold mb-2">{t('account.form.password')}</label>
              <div className="relative">
                <input
                  type={showPassword ? "text" : "password"}
                  id="signup-password"
                  {...register('password')}
                  className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:border-indigo-500 pr-10"
                />
                <button
                  type="button"
                  onClick={togglePasswordVisibility}
                  className="absolute inset-y-0 right-0 pr-3 flex items-center text-sm leading-5"
                >
                  {showPassword ? <EyeOff className="h-5 w-5 text-gray-500" /> : <Eye className="h-5 w-5 text-gray-500" />}
                </button>
              </div>
              {errors.password && (
                <Alert>
                  {errors.password.type === 'min'
                    ? t('account.form.error.passwordMin')
                    : t(`account.form.error.${errors.password.type}`, { field: t('account.form.password') })}
                </Alert>
              )}
            </div>
            
            <p className="text-xs text-gray-500 text-center mt-2 mb-4">
              {t('account.signup.consentMessage')}  <br/>
              <Link to="/terms-of-service" className="text-indigo-600 hover:text-indigo-800">{t('account.signup.termsOfService')}</Link> {t('account.signup.and')} &nbsp;
              <Link to="/privacy-policy" className="text-indigo-600 hover:text-indigo-800">{t('account.signup.privacyPolicy')}</Link>
            </p>
            
            <button 
              type="submit" 
              className="w-full bg-indigo-600 text-white py-2 px-4 rounded-lg hover:bg-indigo-700 transition duration-300"
              disabled={isLoading}>
                {isLoading ? t('account.signup.submitting') : t('account.signup.submit')}
              </button>
          </form>
          
          <div className="mt-6 text-center">
            <p>{t('account.signup.haveAccount')} <Link to="/login" className="text-indigo-600 hover:text-indigo-800 font-semibold">{t('account.login.title')}</Link></p>
          </div>
        </div>
        
        <div className="bg-white rounded-lg shadow-md overflow-hidden p-6">
          <h2 className="text-2xl font-bold mb-4">{t('account.signup.process.title')}</h2>
          <ol className="list-decimal list-inside space-y-2 text-gray-700">
            <li>{t('account.signup.process.step1')}</li>
            <li>{t('account.signup.process.step2')}</li>
            {/* <li>{t('account.signup.process.step3')}</li> */}
            <li>{t('account.signup.process.step4')}</li>
          </ol>
          <p className="mt-4 text-sm text-gray-600">
            {t('account.signup.process.note')}
          </p>
        </div>
      </div>
    </Layout>
  );
}

export default SignUp;