import React from 'react';
import { useTranslation } from 'react-i18next';
import { Smartphone, Apple } from 'lucide-react';

function DownloadSection() {
  const { t } = useTranslation();

  return (
    <section id="download" className="mb-12 sm:mb-20">
      <h2 className="text-3xl sm:text-4xl font-bold mb-6 sm:mb-8 text-center">{t('home.download.title')}</h2>
      <div className="bg-white p-6 sm:p-8 rounded-lg shadow-lg text-center">
        <p className="text-base sm:text-lg text-gray-700 mb-6">{t('home.download.description')}</p>
        <div className="flex flex-col sm:flex-row justify-center items-center space-y-4 sm:space-y-0 sm:space-x-6">
          <a
            href="https://play.google.com/store/apps/details?id=com.Taphere.app"
            target="_blank"
            rel="noopener noreferrer"
            className="bg-green-600 text-white py-2 px-6 rounded-lg flex items-center justify-center space-x-2 hover:bg-green-700 transition duration-300"
          >
            <Smartphone size={24} />
            <span>Google Play</span>
          </a>
          <a
            href="https://apps.apple.com/app/Taphere/id123456789"
            target="_blank"
            rel="noopener noreferrer"
            className="bg-blue-600 text-white py-2 px-6 rounded-lg flex items-center justify-center space-x-2 hover:bg-blue-700 transition duration-300"
          >
            <Apple size={24} />
            <span>App Store</span>
          </a>
        </div>
      </div>
    </section>
  );
}

export default DownloadSection;