import React, { useEffect, useState, useRef, useCallback } from 'react';
import { ChevronRight, ChevronDown, Wifi, Battery, Signal } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import MiniAudioPlayer from './MiniAudioPlayer';
import constants from '@constants';
import ImageCarousel from '@components/manage/point/ImageCarousel';
import ImageModal from '@components/manage/point/ImageModal';

const MOCKUP_WIDTH = 442;
const MOCKUP_HEIGHT = 914;
const ASPECT_RATIO = MOCKUP_WIDTH / MOCKUP_HEIGHT;

const COLOR_OPTIONS = {
  yellow: { title: 'text-yellow-300', subtitle: 'text-yellow-200' },
  blue: { title: 'text-blue-300', subtitle: 'text-blue-200' },
  green: { title: 'text-green-300', subtitle: 'text-green-200' },
  purple: { title: 'text-purple-300', subtitle: 'text-purple-200' },
  pink: { title: 'text-pink-300', subtitle: 'text-pink-200' },
};

const GuidePreview = ({ guideData, point = null, highlightColor, selectedLang, onLanguageChange }) => {
  const navigate = useNavigate();

  const startY = useRef(null);
  const currentY = useRef(null);

  const { t } = useTranslation();
  const [showLanguageModal, setShowLanguageModal] = useState(false);
  const [selectedPoint, setSelectedPoint] = useState(null);
  const [bottomSheetOpen, setBottomSheetOpen] = useState(!!point);
  const [currentTime, setCurrentTime] = useState(new Date());
  const contentRef = useRef(null);
  const bottomSheetRef = useRef(null);
  const [isDragging, setIsDragging] = useState(false);
  const [scale, setScale] = useState(1);
  const containerRef = useRef(null);
  const [selectedImage, setSelectedImage] = useState(null);

  const [showMiniPlayer, setShowMiniPlayer] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const [progress, setProgress] = useState(0);
  const [isSpeechSynthesisSupported, setIsSpeechSynthesisSupported] = useState(true);

  const synth = window.speechSynthesis;
  const utteranceRef = useRef(null);

  const [prevTitle, setPrevTitle] = useState('');
  const [prevSubtitle, setPrevSubtitle] = useState('');
  const [titleChanged, setTitleChanged] = useState(false);
  const [subtitleChanged, setSubtitleChanged] = useState(false);

  const imageUrl = guideData.image || constants.common.CATEGORY_IMAGE_MAP[guideData.category];
  const title = guideData[`title_${selectedLang}`] || guideData.title;
  const subtitle = guideData[`subtitle_${selectedLang}`] || guideData.subtitle;

  const languages = constants.common.LANGUAGES.filter(l => guideData.support_language.includes(l.code));

  useEffect(() => {
    if (title !== prevTitle) {
      setTitleChanged(true);
      setTimeout(() => setTitleChanged(false), 1000);
      setPrevTitle(title);
    }
    if (subtitle !== prevSubtitle) {
      setSubtitleChanged(true);
      setTimeout(() => setSubtitleChanged(false), 1000);
      setPrevSubtitle(subtitle);
    }
  }, [title, subtitle, prevTitle, prevSubtitle]);

  useEffect(() => {
    if (!('speechSynthesis' in window)) {
      setIsSpeechSynthesisSupported(false);
    }

    return () => {
      if (isSpeechSynthesisSupported && utteranceRef.current) {
        synth.cancel();
      }
    }
  }, []);

  const handlePointClick = (point) => {
    setSelectedPoint(point);
    setBottomSheetOpen(true);
    setShowMiniPlayer(true);
  };

  const playAudio = async (point) => {
    if (!isSpeechSynthesisSupported) return;

    if (utteranceRef.current) {
      synth.cancel();
    }

    const text = point[`audio_script_${selectedLang}`];

    if (!text) {
      return;
    }

    utteranceRef.current = new SpeechSynthesisUtterance(text);
    
    utteranceRef.current.lang = selectedLang;
    utteranceRef.current.onstart = () => setIsPlaying(true);
    utteranceRef.current.onend = () => {
      setIsPlaying(false);
      setProgress(100);
    };
    utteranceRef.current.onpause = () => setIsPlaying(false);
    utteranceRef.current.onresume = () => setIsPlaying(true);
    
    utteranceRef.current.onboundary = (event) => {
      const { charIndex, charLength } = event;
      const progressPercent = (charIndex / text.length) * 100;
      setProgress(progressPercent);
    };

    synth.speak(utteranceRef.current);
  };

  const handlePlayPause = () => {
    if (!isSpeechSynthesisSupported) return;

    if (isPlaying) {
      synth.pause();
    } else {
      if (synth.paused) {
        synth.resume();
      } else if (selectedPoint) {
        playAudio(selectedPoint);
      }
    }
  };

  const closeBottomSheet = useCallback(() => {
    setBottomSheetOpen(false);
    bottomSheetRef.current.style.transform = '';
    setTimeout(() => {
      setSelectedPoint(null);
      setShowMiniPlayer(false);
      if (isSpeechSynthesisSupported) {
        synth.cancel();
      }
      setIsPlaying(false);
      setProgress(0);
      if (bottomSheetRef.current) {
        bottomSheetRef.current.style.transform = 'translateY(100%)';
      }
    }, 300);
  }, [isSpeechSynthesisSupported]);

  useEffect(() => {
    return () => {
      if (isSpeechSynthesisSupported && utteranceRef.current) {
        synth.cancel();
      }
    };
  }, [isSpeechSynthesisSupported]);

  useEffect(() => {
    if (point) {
      handlePointClick(point);
    }
  }, [point]);

  useEffect(() => {
    if (selectedPoint) {
      if (isSpeechSynthesisSupported && !point) {
        playAudio(selectedPoint);
      }
    }
  }, [selectedPoint]);

  useEffect(() => {
    const updateScale = () => {
      if (containerRef.current) {
        const containerWidth = containerRef.current.offsetWidth;
        const containerHeight = containerRef.current.offsetHeight;
        const containerAspectRatio = containerWidth / containerHeight;

        if (containerAspectRatio > ASPECT_RATIO) {
          setScale(containerHeight / MOCKUP_HEIGHT);
        } else {
          setScale(containerWidth / MOCKUP_WIDTH);
        }
      }
    };

    updateScale();
    window.addEventListener('resize', updateScale);
    return () => window.removeEventListener('resize', updateScale);
  }, []);

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentTime(new Date());
    }, 1000);

    return () => {
      clearInterval(timer);
    };
  }, []);

  useEffect(() => {
    if(point){
      setSelectedPoint(point);
      if(!bottomSheetOpen){
        setBottomSheetOpen(true);
      }
    }
  }, [point]);

  useEffect(() => {
    if (bottomSheetOpen && selectedPoint && isSpeechSynthesisSupported && !point) {
      setIsPlaying(false);
      setProgress(0);

      synth.cancel();
      
      setTimeout(() => {
        playAudio(selectedPoint);
      }, 300);
    }
  }, [selectedLang]);

  const toggleLanguageModal = () => setShowLanguageModal(!showLanguageModal);

  const handleLanguageSelect = (langCode) => {
    onLanguageChange(langCode);
    setShowLanguageModal(false);
  };

  const formatTime = (date) => {
    return date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
  };

  // const closeBottomSheet = useCallback(() => {
  //   if(point) return;

  //   setBottomSheetOpen(false);
  //   bottomSheetRef.current.style.transform = '';
  //   setTimeout(() => {
  //     setSelectedPoint(null);
  //     if (bottomSheetRef.current) {
  //       bottomSheetRef.current.style.transform = 'translateY(100%)';
  //     }
  //   }, 300);
  // }, []);

  const handleDragMove = useCallback((event) => {
    if (!isDragging) return;
    const clientY = event.clientY || event.touches?.[0]?.clientY;
    currentY.current = clientY;
    const diff = currentY.current - startY.current;
    if (diff > 0) {
      bottomSheetRef.current.style.transform = `translateY(${diff}px)`;
    }
  }, [isDragging]);

  const handleDragEnd = useCallback(() => {
    if (!isDragging) return;
    setIsDragging(false);
    bottomSheetRef.current.style.transition = 'transform 0.3s ease-out';
    document.body.style.userSelect = '';
    if (currentY.current - startY.current > 100) {
      closeBottomSheet();
    } else {
      bottomSheetRef.current.style.transform = '';
    }
    startY.current = null;
    currentY.current = null;
  }, [isDragging, closeBottomSheet]);

  const handleStart = useCallback((event) => {
    if(point) return;

    const target = event.target || event.touches[0].target;
    const carouselElement = bottomSheetRef.current.querySelector('.image-carousel');
    
    // 클릭된 요소가 이미지 캐러셀 내부에 있는지 확인
    if (carouselElement && carouselElement.contains(target)) {
      // 이미지 캐러셀 내부 클릭시 드래그 시작하지 않음
      return;
    }

    const clientY = event.clientY || event.touches[0].clientY;
    startY.current = clientY;
    setIsDragging(true);
    bottomSheetRef.current.style.transition = 'none';
    document.body.style.userSelect = 'none';
  }, []);

  const handleMove = useCallback((event) => {
    if (!isDragging) return;
    const clientY = event.clientY || event.touches[0].clientY;
    currentY.current = clientY;
    const diff = currentY.current - startY.current;
    if (diff > 0) {
      bottomSheetRef.current.style.transform = `translateY(${diff}px)`;
    }
  }, [isDragging]);

  const handleEnd = useCallback(() => {
    if (!isDragging) return;
    setIsDragging(false);
    bottomSheetRef.current.style.transition = 'transform 0.3s ease-out';
    document.body.style.userSelect = '';
    if (currentY.current - startY.current > 100) {
      closeBottomSheet();
    } else {
      bottomSheetRef.current.style.transform = '';
    }
    startY.current = null;
    currentY.current = null;
  }, [isDragging, closeBottomSheet])

  const handleTouchStart = useCallback((e) => handleStart(e), [handleStart]);
  const handleTouchMove = useCallback((e) => handleMove(e), [handleMove]);
  const handleTouchEnd = useCallback(handleEnd, [handleEnd]);
  const handleMouseDown = useCallback((e) => handleStart(e), [handleStart]);

  useEffect(() => {
    if (bottomSheetOpen) {
      document.addEventListener('mousemove', handleDragMove);
      document.addEventListener('mouseup', handleDragEnd);
      document.addEventListener('touchmove', handleDragMove);
      document.addEventListener('touchend', handleDragEnd);
    } else {
      document.removeEventListener('mousemove', handleDragMove);
      document.removeEventListener('mouseup', handleDragEnd);
      document.removeEventListener('touchmove', handleDragMove);
      document.removeEventListener('touchend', handleDragEnd);
    }
    return () => {
      document.removeEventListener('mousemove', handleDragMove);
      document.removeEventListener('mouseup', handleDragEnd);
      document.removeEventListener('touchmove', handleDragMove);
      document.removeEventListener('touchend', handleDragEnd);
      document.body.style.userSelect = '';
    };
  }, [bottomSheetOpen, handleDragMove, handleDragEnd]);

  // const handleOutsideClick = useCallback((e) => {
  //   if (bottomSheetOpen && bottomSheetRef.current && !bottomSheetRef.current.contains(e.target)) {
  //     closeBottomSheet();
  //   }
  // }, [bottomSheetOpen, closeBottomSheet]);

  // useEffect(() => {
  //   document.addEventListener('mousedown', handleOutsideClick);
  //   return () => {
  //     document.removeEventListener('mousedown', handleOutsideClick);
  //   };
  // }, [handleOutsideClick]);

  const handleImageClick = (image) => {
    setSelectedImage(image);
  };

  const titleColor = COLOR_OPTIONS[highlightColor]?.title || COLOR_OPTIONS.yellow.title;
  const subtitleColor = COLOR_OPTIONS[highlightColor]?.subtitle || COLOR_OPTIONS.yellow.subtitle;

  return (
    <div className="w-full h-full overflow-hidden" ref={containerRef}>
      <div 
        className="relative overflow-hidden"
        style={{
          width: `${MOCKUP_WIDTH}px`,
          height: `${MOCKUP_HEIGHT}px`,
          transform: `scale(${scale})`,
          transformOrigin: 'top left'
        }}
      >
        <img 
          src={require('@/assets/images/galaxy.webp')}
          alt="Phone mockup" 
          className="absolute top-0 left-0 w-full h-full object-contain pointer-events-none"
        />
        <div className="absolute inset-[15px] overflow-hidden rounded-[40px]">
          <div className="relative h-full overflow-hidden bg-white">
            <div className="absolute inset-x-0 top-0 h-[200px] bg-gray-100 flex items-center justify-center overflow-hidden">
              {imageUrl ? (
                <img 
                  key={imageUrl}
                  src={imageUrl}
                  alt={title}
                  className="w-full h-full object-cover"
                />
              ) : (
                <svg className="h-12 w-12 text-gray-300" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 16l4.586-4.586a2 2 0 012.828 0L16 16m-2-2l1.586-1.586a2 2 0 012.828 0L20 14m-6-6h.01M6 20h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z" />
                </svg>
              )}
              <div className="absolute inset-0 bg-gradient-to-b from-transparent via-black/0 to-black/50"></div>
            </div>

            <div className="relative bg-black bg-opacity-0 text-white p-2 flex justify-between items-center">
              <span>{formatTime(currentTime)}</span>
              <div className="flex items-center space-x-2">
                <Signal className="w-4 h-4" />
                <Wifi className="w-4 h-4" />
                <Battery className="w-6 h-6" />
              </div>
            </div>

            <div className="relative h-full flex flex-col" ref={contentRef} style={{overflow:'hidden'}}>
              {/* <div className="flex justify-end items-center px-4 z-30 relative"> */}
              {/* <div className="flex justify-between items-center px-4 z-30 relative"> */}
              <div className={`flex ${bottomSheetOpen && !point?'justify-between':'justify-end'} items-center px-4 z-30 relative`}>
                {/* <button 
                  onClick={() => bottomSheetOpen ? closeBottomSheet() : navigate(-1)} 
                  className="bg-indigo-600 rounded-full mt-2 w-10 h-10 flex items-center justify-center shadow-lg border border-white"
                >
                  {bottomSheetOpen ? 
                    <ChevronDown className="w-6 h-6" color='white' /> : 
                    <ChevronLeft className="w-6 h-6" color='white' />
                  }
                </button> */}
                {bottomSheetOpen && !point && <button 
                  onClick={() => bottomSheetOpen ? closeBottomSheet() : navigate(-1)} 
                  className="bg-indigo-600 rounded-full mt-2 w-10 h-10 flex items-center justify-center shadow-lg border border-white"
                >
                  <ChevronDown className="w-6 h-6" color='white' />
                </button>}
                <button 
                  onClick={() => {
                    if (languages.length > 1) {
                      toggleLanguageModal();
                    }
                  }} 
                  className="bg-indigo-600 rounded-full mt-2 w-10 h-10 items-center justify-center shadow-lg border border-white text-white"
                >
                  {selectedLang?.toUpperCase()}
                </button>
              </div>

              {/* <div className="mt-auto px-4 pt-10 h-24 flex flex-col justify-end">
                <h2 className="text-2xl font-bold text-white truncate min-h-[2rem]">{title}</h2>
                <p className="text-lg text-gray-200 truncate min-h-[1.5rem]">{subtitle}</p>
              </div> */}
               <div className="mt-auto px-4 pt-10 h-24 flex flex-col justify-end">
                <h2 
                  className={`text-2xl font-bold truncate min-h-[2rem] transition-all duration-1000 ${
                    titleChanged ? titleColor : 'text-white'
                  }`}
                  style={{
                    textShadow: titleChanged 
                      ? '2px 2px 4px rgba(0,0,0,0.8), -2px -2px 4px rgba(0,0,0,0.8)' 
                      : 'none'
                  }}
                >
                  {title}
                </h2>
                <p 
                  className={`text-lg truncate min-h-[1.5rem] transition-all duration-1000 ${
                    subtitleChanged ? subtitleColor : 'text-gray-200'
                  }`}
                  style={{
                    textShadow: subtitleChanged
                      ? '1px 1px 3px rgba(0,0,0,0.8), -1px -1px 3px rgba(0,0,0,0.8)'
                      : 'none'
                  }}
                >
                  {subtitle}
                </p>
              </div>

              <div className="bg-custom-gradient h-full pt-6 px-4 mt-3 flex flex-col">
                <h3 className="text-xl font-semibold text-white mb-4">
                  {{
                    'ko': '주요 포인트', 
                    'en': 'Main Points',
                    'zh': '主要要点',
                    'ja': '主要ポイント'
                  }[selectedLang]}
                </h3>
                <div className="flex-1 overflow-y-auto scrollbar-hidden">
                  <ul className="space-y-4">
                    {guideData.points.map((point, index) => (
                      <div
                        key={point._id}
                        className="mb-4 bg-white rounded-lg px-2 py-3 flex items-center"
                        style={{ cursor: 'pointer' }}
                        onClick={() => handlePointClick(point)}
                      >
                        <div className="w-10 h-10 bg-indigo-100 rounded-full flex-shrink-0 flex items-center justify-center mr-4">
                          <span className="text-lg font-bold text-indigo-600">
                            {index + 1}
                          </span>
                        </div>
                        <div className="flex-1 min-w-0">
                          <span className="text-lg font-bold mb-1 text-gray-800 block truncate">
                            {point[`name_${selectedLang}`] || point.name}
                          </span>
                        </div>
                        <ChevronRight size={24} color="#4f46e5" className="flex-shrink-0" />
                      </div>
                    ))}
                  </ul>
                </div>
              </div>

              <div 
                ref={bottomSheetRef}
                className={`absolute inset-x-0 bg-white transition-all duration-300 ease-in-out ${
                  isDragging ? 'cursor-grabbing' : 'cursor-grab'
                } `}
                style={{
                  height: '100%',
                  zIndex: 10,
                  touchAction: 'none',
                  transform: bottomSheetOpen ? 'translateY(0)' : 'translateY(100%)'
                }}
                onTouchStart={handleTouchStart}
                onTouchMove={handleTouchMove}
                onTouchEnd={handleTouchEnd}
                onMouseDown={handleMouseDown}
              >
                <div className="w-16 h-1 bg-gray-300 rounded-full mx-auto mt-2"></div>
                <div className='relative h-full inset-x-2 mx-4 mt-14 '>
                  <div className="flex justify-between items-center mb-1">
                    <h3 className="text-xl font-bold">
                      {selectedPoint?.[`name_${selectedLang}`] || selectedPoint?.name}
                    </h3>
                  </div>
                  <div className={`w-full overflow-y-auto ${isDragging ? 'pointer-events-none' : ''}`} style={{ maxHeight: 'calc(100% - 2rem)' }}>
                    {selectedPoint?.images && selectedPoint.images.length > 0 && (
                      <div className="image-carousel">
                        <ImageCarousel 
                          images={selectedPoint.images} 
                          onImageClick={handleImageClick}
                        />
                      </div>
                    )}
                    <p className="text-gray-600">
                      {selectedPoint?.[`description_${selectedLang}`] || selectedPoint?.description}
                    </p>
                  </div>
                </div>
                
              </div>
              {bottomSheetOpen && !point && (
                <MiniAudioPlayer
                  audioData={selectedPoint}
                  selectedLang={selectedLang}
                  isPlaying={isPlaying}
                  progress={progress}
                  onPlayPause={handlePlayPause}
                  onClose={closeBottomSheet}
                  isSpeechSynthesisSupported={isSpeechSynthesisSupported}
                />
              )}
            </div>

            {selectedImage && (
              <ImageModal
                image={selectedImage}
                onClose={() => setSelectedImage(null)}
              />
            )}

            {showLanguageModal && (
              <div className="absolute top-24 right-4 bg-white p-2 z-20">
                {languages.map((lang) => (
                  <button
                    key={lang.code}
                    onClick={() => handleLanguageSelect(lang.code)}
                    className={`block w-full text-left px-4 py-2 text-sm ${
                      selectedLang === lang.code ? 'bg-indigo-100 text-indigo-800' : 'hover:bg-gray-100'
                    }`}
                  >
                    {lang.name}
                  </button>
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="absolute bottom-16 left-0 right-0 text-center text-sm text-gray-400">
        {t('disclaimerText')}
      </div>
    </div>
  );
};

export default GuidePreview;