import React from 'react';
import { useLogoutMutation } from '../../services/authService';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import { ChevronLeft } from 'lucide-react';
import { useTranslation } from 'react-i18next';

const Header = ({ title, message, homePath, previousPath }) => {
  const location = useLocation();
  const navigate = useNavigate();

  const { t } = useTranslation();

  const [logout, { isLoading }] = useLogoutMutation();

  const handleLogout = async () => {
    try {
      await logout().unwrap();
      navigate('/login', { replace: true });
    } catch (err) {
      console.error('Failed to log out:', err);
    }
  }

  const handleBack = () => {
    const pathSegments = location.pathname.split('/').filter(Boolean);

    if(previousPath){
      navigate(previousPath, { replace: true });
    } else if (pathSegments.length > 1) {
      const parentPath = '/' + pathSegments.slice(0, -1).join('/');
      navigate(parentPath, { replace: true });
    } else if (location.pathname !== homePath) {
      navigate(homePath, { replace: true });
    } else if (window.history.length > 2 && document.referrer.includes(window.location.host)) {
      navigate(-1, { replace: true });
    }
  }

  return (
    <header className="bg-white shadow">
      <div className={`max-w-7xl mx-auto ${message ? 'py-4' : 'py-6'} px-4 sm:px-6 lg:px-8 flex justify-between items-center`}>
        <div className="flex items-center text-3xl font-bold text-gray-900">
          {location.pathname.split('/').filter(Boolean).length > 1 && (
            <ChevronLeft className="w-10 h-10 cursor-pointer mr-2" onClick={handleBack} />
          )}
          {message ? <div className="max-w-7xl mx-auto">
                      <h2 className="text-3xl font-bold text-gray-900">{title}</h2>
                      <p className="text-sm text-gray-500">{message}</p>
                    </div> : <h2 className="text-3xl font-bold text-gray-900">{title}</h2>}
        </div>
        <button onClick={handleLogout} className="bg-red-600 text-white px-3 py-1 rounded-md hover:bg-red-700 transition duration-300">
          {/* {isLoading ? '로그아웃 중...' : '로그아웃'} */}
          {t('common.logout')}
        </button>
      </div>
    </header>
  )
}

export default Header;