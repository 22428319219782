import React, { useRef, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import Layout from '@components/account/Layout';
import { useSelector, useDispatch } from 'react-redux';
import { setUser } from '@store/slice/authSlice';
import { useVerifyEmailMutation, useVerifyEmailRequestMutation } from '../../services/authService';
import confetti from 'canvas-confetti';

const EmailVerification = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const [verificationCode, setVerificationCode] = useState(['', '', '', '', '', '']);
  const [isVerified, setIsVerified] = useState(false);
  const [countdown, setCountdown] = useState(5);
  const inputRefs = [useRef(), useRef(), useRef(), useRef(), useRef(), useRef()];
  const { user } = useSelector((state) => state.auth);
  const [verifyEmail, { isLoading, isError, error }] = useVerifyEmailMutation();
  const [verifyEmailRequest, { isLoading: isResending }] = useVerifyEmailRequestMutation();

  useEffect(() => {
    if (isVerified) {
      confetti({
        particleCount: 100,
        spread: 70,
        origin: { y: 0.6 }
      });

      const timer = setInterval(() => {
        setCountdown((prevCount) => prevCount - 1);
      }, 1000);

      const redirectTimer = setTimeout(() => {
        dispatch(setUser({ ...user, is_verified_email: true }));
        navigate('/guides', { replace: true });
      }, 5000);

      return () => {
        clearInterval(timer);
        clearTimeout(redirectTimer);
      };
    }
  }, [isVerified, navigate]);

  const validationSchema = Yup.object().shape({
    verificationCode: Yup.string()
      .required(t('account.emailVerification.validation.codeRequired'))
      .matches(/^[0-9]{6}$/, t('account.emailVerification.validation.codeInvalid'))
  });

  const { handleSubmit, setError, clearErrors, formState: { errors }, setValue, reset } = useForm({
    resolver: yupResolver(validationSchema)
  });

  useEffect(() => {
    const code = verificationCode.join('');
    setValue('verificationCode', code);
  }, [verificationCode, setValue]);

  const handleChange = (index, value) => {
    if (!/^[0-9]$/.test(value) && value !== '') return;

    const newCode = [...verificationCode];
    newCode[index] = value;
    setVerificationCode(newCode);
    clearErrors('verificationCode');

    if (value !== '' && index < 5) {
      inputRefs[index + 1].current.focus();
    }
  };

  const handleKeyDown = (index, e) => {
    if (e.key === 'Backspace' && verificationCode[index] === '' && index > 0) {
      inputRefs[index - 1].current.focus();
    }
  };

  const handlePaste = (e) => {
    e.preventDefault();
    const pastedData = e.clipboardData.getData('text').trim();
    if (/^[0-9]{6}$/.test(pastedData)) {
      const newCode = pastedData.split('');
      setVerificationCode(newCode);
      setValue('verificationCode', pastedData);
      inputRefs[5].current.focus();
    }
  };

  const onSubmit = async (data) => {
    const code = data.verificationCode;
    if (code.length !== 6) {
      setError('verificationCode', {
        type: 'manual',
        message: t('account.emailVerification.validation.codeInvalid')
      });
      return;
    }
    
    try {
      const result = await verifyEmail({
        lang_code: i18n.language,
        code
      }).unwrap();
      
      setIsVerified(true);

    } catch (err) {
      // console.error('Failed to verify email:', err);
      setError('verificationCode', {
        type: 'manual',
        message: t('account.emailVerification.validation.verificationFailed')
      });
    }
  };

  const handleResendCode = async () => {
    try {
      await verifyEmailRequest({ lang_code: i18n.language }).unwrap();
      setVerificationCode(['', '', '', '', '', '']);
      reset();
    } catch (err) {
      console.error('Failed to resend verification code:', err);
    }
  };

  if (isVerified) {
    return (
      <Layout>
        <div className="w-full max-w-md">
          <div className="bg-white rounded-lg shadow-md overflow-hidden p-6 text-center">
            <div className="animate-scale">
              <svg className="w-24 h-24 text-green-500 mx-auto mb-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"></path>
              </svg>
              <h2 className="text-3xl font-bold mb-4 text-green-600">{t('account.emailVerificationConfirm.title')}</h2>
              <p className="text-xl mb-4">{t('account.emailVerificationConfirm.successMessage')}</p>
              <p className="text-lg mb-6">{t('account.emailVerificationConfirm.welcomeMessage')}</p>
              <p className="text-md mb-4">{t('account.emailVerificationConfirm.redirectMessage', { seconds: countdown })}</p>
            </div>
            <button
              onClick={() => {
                dispatch(setUser({ ...user, is_verified_email: true }));
                navigate('/guides', { replace: true })}
              }
              className="inline-block bg-indigo-600 text-white py-2 px-6 rounded-lg hover:bg-indigo-700 transition duration-300"
            >
              {t('account.emailVerificationConfirm.dashboardButton')}
            </button>
          </div>
        </div>
      </Layout>
    );
  }

  return (
    <Layout>
      <div className="w-full max-w-md">
        <div className="bg-white rounded-lg shadow-md overflow-hidden p-6 mb-8">
          <h2 className="text-2xl font-bold mb-4 text-center">{t('account.emailVerification.title')}</h2>
          <p className="text-center mb-6">{t('account.emailVerification.description')}</p>
          
          {user?.email && (
            <p className="text-center mb-6 text-sm text-gray-600">
              {t('account.emailVerification.emailSentTo', { email: user.email })}
            </p>
          )}
          
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="mb-4">
              <label htmlFor="verificationCode" className="block text-sm font-medium text-gray-700 mb-2">
                {t('account.emailVerification.codeLabel')}
              </label>
              <div className="flex justify-between">
                {verificationCode.map((digit, index) => (
                  <input
                    key={index}
                    ref={inputRefs[index]}
                    type="text"
                    maxLength="1"
                    className="w-12 h-12 text-center text-2xl border border-gray-300 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                    value={digit}
                    onChange={(e) => handleChange(index, e.target.value)}
                    onKeyDown={(e) => handleKeyDown(index, e)}
                    onPaste={handlePaste}
                  />
                ))}
              </div>
              {errors.verificationCode && (
                <p className="mt-2 text-sm text-red-600">{errors.verificationCode.message}</p>
              )}
              {isError && (
                <p className="mt-2 text-sm text-red-600">
                  {error?.data?.message || t('account.emailVerification.validation.verificationFailed')}
                </p>
              )}
            </div>
            <button
              type="submit"
              disabled={isLoading || verificationCode.some(digit => digit === '')}
              className={`w-full py-2 px-4 rounded-lg transition duration-300 ${
                isLoading || verificationCode.some(digit => digit === '')
                  ? 'bg-indigo-400 cursor-not-allowed'
                  : 'bg-indigo-600 text-white hover:bg-indigo-700'
              }`}
            >
              {isLoading ? t('account.emailVerification.verifying') : t('account.emailVerification.verifyButton')}
            </button>
          </form>
          
          <p className="text-center text-sm text-gray-600 mt-6 mb-2">{t('account.emailVerification.resendCodeText')}</p>
          <button
            onClick={handleResendCode}
            disabled={isResending}
            className="w-full bg-gray-200 text-gray-800 py-2 px-4 rounded-lg hover:bg-gray-300 transition duration-300"
          >
            {isResending ? t('account.emailVerification.resending') : t('account.emailVerification.resendCodeButton')}
          </button>
        </div>
        
        <div className="bg-white rounded-lg shadow-md overflow-hidden p-6">
          <h2 className="text-2xl font-bold mb-4">{t('account.emailVerification.instructions.title')}</h2>
          <ul className="list-disc list-inside space-y-2 text-gray-700">
            <li>{t('account.emailVerification.instructions.step1')}</li>
            <li>{t('account.emailVerification.instructions.step2')}</li>
            <li>{t('account.emailVerification.instructions.step3')}</li>
          </ul>
        </div>
      </div>
    </Layout>
  );
};

export default EmailVerification;