import React, { useState, useEffect, useMemo } from "react";
import Layout from "@components/manage/Layout";
import OverviewCard from "@components/manage/analysis/OverviewCard";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarkedAlt, faUsers, faClock, faExclamationTriangle, faTrophy } from '@fortawesome/free-solid-svg-icons';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { useGetDashboardDataQuery } from "@services/analysisService";
import { useTranslation } from 'react-i18next';
import LoadingSpinner from "@components/LoadingSpinner";
import Helpers from "@helpers";

const getLocalizedText = (obj, language, prefix = 'title', fallbackOrder = ['en', 'ko', 'ja', 'zh']) => {
  if (obj[`${prefix}_${language}`]) {
    return obj[`${prefix}_${language}`];
  }
  
  for (let lang of fallbackOrder) {
    if (obj[`${prefix}_${lang}`]) {
      return obj[`${prefix}_${lang}`];
    }
  }
  
  if (obj[prefix]) {
    return obj[prefix];
  }
  
  return Object.values(obj).find(val => typeof val === 'string') || '';
};

const ActiveGuideCard = ({ guide, onCardClick }) => {
  const { i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const title = getLocalizedText(guide, currentLanguage);

  return (
    <div
      className="bg-white overflow-hidden shadow rounded-lg transition-all duration-300 hover:shadow-lg cursor-pointer"
      onClick={() => onCardClick(guide)}
    >
      <div className="p-5">
        <div className="flex items-center justify-between">
          <div className="flex-1">
            <h3 className="text-lg font-medium text-gray-900 truncate">{title}</h3>
            <p className="text-sm text-gray-500 mt-1">
              <FontAwesomeIcon icon={faUsers} className="mr-2" />
              {guide.users} 명 사용 중
            </p>
          </div>
          <div className="flex-shrink-0 ml-4">
            <div className="bg-indigo-500 rounded-full p-3">
              <FontAwesomeIcon icon={faMapMarkedAlt} className="h-6 w-6 text-white" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const TopGuideCard = ({ guide, rank }) => {
  const { i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const title = getLocalizedText(guide, currentLanguage);

  return (
    <div className="bg-white overflow-hidden shadow rounded-lg">
      <div className="p-5">
        <div className="flex items-center">
          <div className="flex-shrink-0 bg-indigo-500 rounded-full p-3">
            <FontAwesomeIcon icon={faTrophy} className="h-6 w-6 text-white" />
          </div>
          <div className="ml-5 w-0 flex-1">
            <dl>
              <dt className="text-sm font-medium text-gray-500 truncate">
                {rank}위 - {title}
              </dt>
              <dd className="text-lg font-medium text-gray-900">
                {guide.users} 회 사용
              </dd>
            </dl>
          </div>
        </div>
      </div>
    </div>
  );
};

const GuideModal = ({ guide, onClose }) => {
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;

  if (!guide) return null;

  const title = getLocalizedText(guide, currentLanguage);

  return (
    <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full" onClick={onClose}>
      <div className="relative top-20 mx-auto p-5 border w-96 shadow-lg rounded-md bg-white" onClick={e => e.stopPropagation()}>
        <div className="mt-3 text-center">
          <h3 className="text-lg leading-6 font-medium text-gray-900">{title}</h3>
          <div className="mt-2 px-7 py-3">
            <p className="text-sm text-gray-500">{t('Dashboard.totalUsers')}: {guide.users}명</p>
            {guide.points && guide.points.length > 0 && (
              <>
                <h4 className="text-md font-medium text-gray-900 mt-4 mb-2">{t('Dashboard.pointList')}:</h4>
                <ul className="space-y-2">
                  {guide.points.map((point, index) => {
                    const pointName = getLocalizedText(point, currentLanguage, 'name');
                    return (
                      <li key={index} className="flex justify-between text-sm">
                        <span className="break-words text-left flex-1 mr-2">{pointName}</span>
                        <span className="flex-shrink-0">{point.users} 명</span>
                      </li>
                    );
                  })}
                </ul>
              </>
            )}
          </div>
          <div className="items-center px-4 py-3">
            <button
              className="px-4 py-2 bg-blue-500 text-white text-base font-medium rounded-md w-full shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-300"
              onClick={onClose}
            >
              {t('Dashboard.close')}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};


const Dashboard = () => {
  const { t, i18n } = useTranslation();
  const [selectedGuide, setSelectedGuide] = useState(null);
  const { data, refetch } = useGetDashboardDataQuery(null, {
    pollingInterval: 3000, // Refetch every 3 seconds
  });
  
  useEffect(() => {
    const interval = setInterval(() => {
      refetch();
    }, 3000);

    return () => clearInterval(interval);
  }, [refetch]);

  const chartData = useMemo(() => {
    if (!data) return [];
    return data.hourlyUsers.map(item => ({
      time: `${item.hour}:00`,
      users: item.users
    }));
  }, [data]);

  const topGuides = useMemo(() => {
    if (!data) return [];
    return data.topGuides;
  }, [data]);

  if (!data) return <LoadingSpinner isLoading={true} />;

  return (
    <Layout>
      <div className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8 px-4">
        <div className="grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3 mb-8">
          <OverviewCard 
            icon={faUsers} 
            title={t('Dashboard.todayVisitors')}
            value={data.todayStats.visitors} 
            bgColor="bg-blue-500" 
          />
          <OverviewCard 
            icon={faClock} 
            title={t('Dashboard.todayUsageTime')}
            value={`${Helpers.formatTime(data.todayStats.usageTime, i18n.language)}`} 
            bgColor="bg-green-500" 
          />
          <OverviewCard 
            icon={faMapMarkedAlt} 
            title={t('Dashboard.currentActiveGuides')}
            value={data.todayStats.activeGuides} 
            bgColor="bg-yellow-500" 
          />
        </div>

        <div className="mb-8">
          <h2 className="text-lg leading-6 font-medium text-gray-900 mb-4">{t('Dashboard.hourlyUsers')}</h2>
          <div className="bg-white p-4 rounded-lg shadow">
            <ResponsiveContainer width="100%" height={300}>
              <LineChart data={chartData} margin={{ top:30, right: 30 }}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis 
                  dataKey="time" 
                  tick={{fill: '#4B5563'}} 
                  tickLine={{stroke: '#4B5563'}}
                  tickFormatter={(value) => value.replace(':00', '')}
                />
                <YAxis 
                  tick={{fill: '#4B5563'}} 
                  tickLine={{stroke: '#4B5563'}}
                />
                <Tooltip 
                  contentStyle={{backgroundColor: '#F3F4F6', border: 'none', borderRadius: '4px'}}
                  itemStyle={{color: '#4B5563'}}
                />
                <Legend wrapperStyle={{paddingTop: '10px'}} />
                <Line 
                  type="monotone" 
                  dataKey="users" 
                  stroke="#8884d8" 
                  strokeWidth={2}
                  dot={{r: 4}}
                  activeDot={{r: 6, stroke: '#8884d8', strokeWidth: 2}}
                />
              </LineChart>
            </ResponsiveContainer>
          </div>
        </div>

        <div className="mb-8">
          <h2 className="text-lg leading-6 font-medium text-gray-900 mb-4">{t('Dashboard.topGuides')}</h2>
            {topGuides.length > 0 ? (
              <div className="grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3">
                {topGuides.map((guide, index) => (
                  <TopGuideCard key={guide.id} guide={guide} rank={index + 1} />
                ))}
              </div>
            ) : (
              <div className="bg-yellow-50 border-l-4 border-yellow-400 p-4">
                <div className="flex">
                  <div className="flex-shrink-0">
                    <FontAwesomeIcon icon={faExclamationTriangle} className="h-5 w-5 text-yellow-400" />
                  </div>
                  <div className="ml-3">
                    <p className="text-sm text-yellow-700">
                      {t('Dashboard.noPopularGuides')}
                    </p>
                  </div>
                </div>
              </div>
            )}
        </div>

        <div className="mb-8">
          <div className="flex justify-between items-center mb-4">
            <h2 className="text-lg leading-6 font-medium text-gray-900">{t('Dashboard.currentActiveGuides')}</h2>
          </div>
          {data.activeGuides.length > 0 ? (
            <div className="grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3">
              {data.activeGuides.map(guide => (
                <ActiveGuideCard key={guide.id} guide={guide} onCardClick={setSelectedGuide} />
              ))}
            </div>
          ) : (
            <div className="bg-yellow-50 border-l-4 border-yellow-400 p-4">
              <div className="flex">
                <div className="flex-shrink-0">
                  <FontAwesomeIcon icon={faExclamationTriangle} className="h-5 w-5 text-yellow-400" />
                </div>
                <div className="ml-3">
                  <p className="text-sm text-yellow-700">
                    {t('Dashboard.noActiveGuides')}
                  </p>
                </div>
              </div>
            </div>
          )}
        </div>

        {selectedGuide && <GuideModal guide={selectedGuide} onClose={() => setSelectedGuide(null)} />}
      </div>
    </Layout>
  );
};

export default Dashboard;