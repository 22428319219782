import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectIsLoggedIn } from '@store/slice/authSlice';
import constants from '@constants';

function Header() {  // isLoggedIn prop 추가
  const { t, i18n } = useTranslation();

  const isLoggedIn = useSelector(selectIsLoggedIn);

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  return (
    <header className="bg-gradient-to-r from-indigo-600 to-purple-600 text-white py-8 sm:py-12">
      <div className="container mx-auto px-4">
        <div className="flex flex-col sm:flex-row justify-between items-center">
          <div className="text-center sm:text-left mb-6 sm:mb-0">
            <h1 className="text-4xl sm:text-5xl font-bold mb-2">Taphere</h1>
            <p className="text-lg sm:text-xl">{t('common.slogan')}</p>
          </div>
          <div className="flex flex-col sm:flex-row items-center space-y-4 sm:space-y-0 sm:space-x-4">
            <select 
              className="bg-white text-gray-800 rounded px-2 py-1 w-full sm:w-auto" 
              defaultValue={i18n.language} 
              onChange={e => changeLanguage(e.target.value)}
            >
              {constants.common.LANGUAGES.map(lang=><option key={lang.code} value={lang.code}>{lang.name}</option>)}
            </select>
            {isLoggedIn ? (
              <Link 
                to="/guides" 
                className="bg-white text-indigo-600 py-2 px-6 rounded-full font-semibold hover:bg-indigo-100 transition duration-300 w-full sm:w-auto text-center"
              >
                {t('home.cta.dashboard')}
              </Link>
            ) : (
              <Link 
                to="/login" 
                className="bg-white text-indigo-600 py-2 px-6 rounded-full font-semibold hover:bg-indigo-100 transition duration-300 w-full sm:w-auto text-center"
              >
                {t('home.cta.start')}
              </Link>
            )}
          </div>
        </div>
      </div>
    </header>
  );
}

export default Header;