import React, { useEffect, useState, useRef, useCallback } from 'react';
import { ChevronRight, ChevronDown, Download, Battery, Signal } from 'lucide-react';
import { useNavigate, Navigate, Link } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';
import MiniAudioPlayer from '@components/manage/guide/MiniAudioPlayer';
import constants from '@constants';
import ImageCarousel from '@components/manage/point/ImageCarousel';
import ImageModal from '@components/manage/point/ImageModal';
import { useGetGuideQuery, useTrackViewMutation, useSendHeartbeatMutation } from '@services/guideService';
import LoadingSpinner from "@components/LoadingSpinner";

const GuideViewScreen = () => {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const searchParams = new URLSearchParams(window.location.search);
  const guideId = searchParams.get('guide_id');

  const [key, setKey] = useState(uuidv4());
  const [deviceId, setDeviceId] = useState(null);
  const [trackView] = useTrackViewMutation();
  const [sendHeartbeat] = useSendHeartbeatMutation();
  const heartbeatIntervalRef = useRef(null);

  const { data: guideData, error, isLoading } = useGetGuideQuery({id: guideId}, { refetchOnMountOrArgChange: true });

  const [selectedLang, setSelectedLang] = useState(i18n.language);
  const [showLanguageModal, setShowLanguageModal] = useState(false);
  const [selectedPoint, setSelectedPoint] = useState(null);
  const [bottomSheetOpen, setBottomSheetOpen] = useState(false);
  const [currentTime, setCurrentTime] = useState(new Date());
  const [isDragging, setIsDragging] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [showMiniPlayer, setShowMiniPlayer] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const [progress, setProgress] = useState(0);
  const [isSpeechSynthesisSupported, setIsSpeechSynthesisSupported] = useState(true);

  const startY = useRef(null);
  const currentY = useRef(null);
  const contentRef = useRef(null);
  const bottomSheetRef = useRef(null);
  const utteranceRef = useRef(null);

  const synth = window.speechSynthesis;

  const handleTrackView = useCallback((type, pointId = null) => {
    if (deviceId && guideId) {
      const body = {
        guide_id: guideId,
        device_id: deviceId,
        type: type,
        key: key,
        platform: 'web'
      }

      if (pointId) {
        body.point_id = pointId;
      }

      trackView(body);
    }
  }, [deviceId, guideId, trackView]);

  useEffect(() => {
    // device_id 설정
    const storedDeviceId = localStorage.getItem('device_id');
    if (storedDeviceId) {
      setDeviceId(storedDeviceId);
    } else {
      const newDeviceId = uuidv4();
      localStorage.setItem('device_id', newDeviceId);
      setDeviceId(newDeviceId);
    }

    // 페이지 로드 시 trackView 호출
    handleTrackView('start');

    // 하트비트 시작
    startHeartbeat();

    // 페이지 언로드 시 trackView 호출
    const handleUnload = () => {
      handleTrackView('end');
    };
    window.addEventListener('beforeunload', handleUnload);

    return () => {
      // 컴포넌트 언마운트 시 cleanup
      if (heartbeatIntervalRef.current) {
        clearInterval(heartbeatIntervalRef.current);
      }
      window.removeEventListener('beforeunload', handleUnload);
      handleTrackView('end');
    };
  }, [deviceId, guideId, handleTrackView]);

  const startHeartbeat = () => {
    if (heartbeatIntervalRef.current) {
      clearInterval(heartbeatIntervalRef.current);
    }

    heartbeatIntervalRef.current = setInterval(() => {
      if (deviceId && guideId) {
        sendHeartbeat({
          guide_id: guideId,
          point_id: selectedPoint?._id,
          device_id: deviceId
        });
      }
    }, 5000); // 5초마다 하트비트 전송
  };

  useEffect(() => {
    if (guideData) {
      if (guideData.support_language.includes(selectedLang)) {
        setSelectedLang(selectedLang);
      } else {
        setSelectedLang(guideData.support_language[0]);
      }

      if (searchParams.get('point_id')) {
        const point = guideData.points.find(p => p._id === searchParams.get('point_id'));
        if (point) {
          handlePointClick(point);
        }
      }
    }
  }, [guideData, searchParams]);

  useEffect(() => {
    if (!('speechSynthesis' in window)) {
      setIsSpeechSynthesisSupported(false);
    }

    return () => {
      if (isSpeechSynthesisSupported && utteranceRef.current) {
        synth.cancel();
      }
    }
  }, []);

  const handlePointClick = useCallback((point) => {
    if (selectedPoint) {
      handleTrackView('end', selectedPoint._id);
    }
    setSelectedPoint(point);
    setBottomSheetOpen(true);
    setShowMiniPlayer(true);
    handleTrackView('start', point._id);
  }, [selectedPoint, handleTrackView]);

  const playAudio = async (point) => {
    if (!isSpeechSynthesisSupported) return;

    if (utteranceRef.current) {
      synth.cancel();
    }

    const text = point[`audio_script_${selectedLang}`];

    if (!text) {
      return;
    }

    utteranceRef.current = new SpeechSynthesisUtterance(text);
    
    utteranceRef.current.lang = selectedLang;
    utteranceRef.current.onstart = () => setIsPlaying(true);
    utteranceRef.current.onend = () => {
      setIsPlaying(false);
      setProgress(100);
    };
    utteranceRef.current.onpause = () => setIsPlaying(false);
    utteranceRef.current.onresume = () => setIsPlaying(true);
    
    utteranceRef.current.onboundary = (event) => {
      const { charIndex, charLength } = event;
      const progressPercent = (charIndex / text.length) * 100;
      setProgress(progressPercent);
    };

    synth.speak(utteranceRef.current);
  };

  const handlePlayPause = () => {
    if (!isSpeechSynthesisSupported) return;

    if (isPlaying) {
      synth.pause();
    } else {
      if (synth.paused) {
        synth.resume();
      } else if (selectedPoint) {
        playAudio(selectedPoint);
      }
    }
  };

  const closeBottomSheet = useCallback(() => {
    if (selectedPoint) {
      handleTrackView('end', selectedPoint._id);
    }
    setBottomSheetOpen(false);
    bottomSheetRef.current.style.transform = '';
    setTimeout(() => {
      setSelectedPoint(null);
      setShowMiniPlayer(false);
      if (isSpeechSynthesisSupported) {
        synth.cancel();
      }
      setIsPlaying(false);
      setProgress(0);
      if (bottomSheetRef.current) {
        bottomSheetRef.current.style.transform = 'translateY(100%)';
      }
    }, 300);
  }, [selectedPoint, handleTrackView, isSpeechSynthesisSupported]);

  useEffect(() => {
    if (selectedPoint) {
      if (isSpeechSynthesisSupported) {
        playAudio(selectedPoint);
      }
    }
  }, [selectedPoint, isSpeechSynthesisSupported]);

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentTime(new Date());
    }, 1000);

    return () => {
      clearInterval(timer);
    };
  }, []);

  useEffect(() => {
    if (bottomSheetOpen && selectedPoint && isSpeechSynthesisSupported) {
      setIsPlaying(false);
      setProgress(0);

      synth.cancel();
      
      setTimeout(() => {
        playAudio(selectedPoint);
      }, 300);
    }
  }, [selectedLang, bottomSheetOpen, selectedPoint, isSpeechSynthesisSupported]);

  const toggleLanguageModal = () => setShowLanguageModal(!showLanguageModal);

  const handleLanguageSelect = (langCode) => {
    i18n.changeLanguage(langCode);
    setSelectedLang(langCode);
    setShowLanguageModal(false);
  };

  const handleDragMove = useCallback((event) => {
    if (!isDragging) return;
    const clientY = event.clientY || event.touches?.[0]?.clientY;
    currentY.current = clientY;
    const diff = currentY.current - startY.current;
    if (diff > 0) {
      bottomSheetRef.current.style.transform = `translateY(${diff}px)`;
    }
  }, [isDragging]);

  const handleDragEnd = useCallback(() => {
    if (!isDragging) return;
    setIsDragging(false);
    bottomSheetRef.current.style.transition = 'transform 0.3s ease-out';
    document.body.style.userSelect = '';
    if (currentY.current - startY.current > 100) {
      closeBottomSheet();
    } else {
      bottomSheetRef.current.style.transform = '';
    }
    startY.current = null;
    currentY.current = null;
  }, [isDragging, closeBottomSheet]);

  const handleStart = useCallback((event) => {
    const target = event.target || event.touches[0].target;
    const carouselElement = bottomSheetRef.current.querySelector('.image-carousel');
    
    if (carouselElement && carouselElement.contains(target)) {
      return;
    }

    const clientY = event.clientY || event.touches[0].clientY;
    startY.current = clientY;
    setIsDragging(true);
    bottomSheetRef.current.style.transition = 'none';
    document.body.style.userSelect = 'none';
  }, []);

  const handleMove = useCallback((event) => {
    if (!isDragging) return;
    const clientY = event.clientY || event.touches[0].clientY;
    currentY.current = clientY;
    const diff = currentY.current - startY.current;
    if (diff > 0) {
      bottomSheetRef.current.style.transform = `translateY(${diff}px)`;
    }
  }, [isDragging]);

  const handleEnd = useCallback(() => {
    if (!isDragging) return;
    setIsDragging(false);
    bottomSheetRef.current.style.transition = 'transform 0.3s ease-out';
    document.body.style.userSelect = '';
    if (currentY.current - startY.current > 100) {
      closeBottomSheet();
    } else {
      bottomSheetRef.current.style.transform = '';
    }
    startY.current = null;
    currentY.current = null;
  }, [isDragging, closeBottomSheet]);

  const handleTouchStart = useCallback((e) => handleStart(e), [handleStart]);
  const handleTouchMove = useCallback((e) => handleMove(e), [handleMove]);
  const handleTouchEnd = useCallback(handleEnd, [handleEnd]);
  const handleMouseDown = useCallback((e) => handleStart(e), [handleStart]);

  useEffect(() => {
    if (bottomSheetOpen) {
      document.addEventListener('mousemove', handleDragMove);
      document.addEventListener('mouseup', handleDragEnd);
      document.addEventListener('touchmove', handleDragMove);
      document.addEventListener('touchend', handleDragEnd);
    } else {
      document.removeEventListener('mousemove', handleDragMove);
      document.removeEventListener('mouseup', handleDragEnd);
      document.removeEventListener('touchmove', handleDragMove);
      document.removeEventListener('touchend', handleDragEnd);
    }
    return () => {
      document.removeEventListener('mousemove', handleDragMove);
      document.removeEventListener('mouseup', handleDragEnd);
      document.removeEventListener('touchmove', handleDragMove);
      document.removeEventListener('touchend', handleDragEnd);
      document.body.style.userSelect = '';
    };
  }, [bottomSheetOpen, handleDragMove, handleDragEnd]);

  const handleImageClick = (image) => {
    setSelectedImage(image);
  };

  const handleAppRedirect = () => {
    // Implement the logic to redirect to the app or app store
    console.log('Redirecting to app...');
  };

  const handleNFCScanner = () => {
    // Implement the logic to open the NFC scanner
    console.log('Opening NFC scanner...');
  };

  const renderTopLeftContent = () => {
    if (bottomSheetOpen) {
      return (
        <button 
          onClick={() => closeBottomSheet()} 
          className="bg-indigo-600 rounded-full mt-2 w-10 h-10 flex items-center justify-center shadow-lg border border-white"
        >
          <ChevronDown className="w-6 h-6" color='white' />
        </button>
      );
    }

    if (true) { // Changed from isMobile for demonstration
      return (
        <div className="flex space-x-2">

        <button 
          onClick={handleAppRedirect}
          className="bg-gradient-to-r from-indigo-500 to-purple-600 rounded-full px-3 py-2 flex items-center justify-center shadow-lg border border-white text-white text-sm font-bold transition-all duration-300 hover:from-indigo-600 hover:to-purple-700 hover:scale-105"
        >
          <Download className="w-4 h-4 mr-2" />
          앱으로 더 편리하게
        </button>
        {/* <button 
        onClick={handleNFCScanner}
        className="bg-blue-500 rounded-full px-3 py-2 flex items-center justify-center shadow-lg border border-white text-white text-sm font-bold transition-all duration-300 hover:bg-blue-600 hover:scale-105"
      >
        NFC 스캔
      </button> */}
      </div>
      );
    }

    return (
      <div className="text-2xl font-bold text-white">
        Taphere
      </div>
    );
  };

  if (isLoading) return <LoadingSpinner isLoading={true}/>;

  if (error) return <Navigate to="/not-found" />;

  const imageUrl = guideData?.image || constants.common.CATEGORY_IMAGE_MAP[guideData?.category];
  const title = guideData?.[`title_${selectedLang}`] || guideData?.title;
  const subtitle = guideData?.[`subtitle_${selectedLang}`] || guideData?.subtitle;

  const languages = constants.common.LANGUAGES.filter(l => guideData?.support_language?.includes(l.code));

  return (
    <div className="w-full h-full flex justify-center bg-gray-100">
    <div className="w-full max-w-[440px] h-full overflow-hidden bg-white shadow-lg flex flex-col">
      <div className="flex-grow overflow-hidden">
        <div className="relative h-full overflow-hidden">
          <div className="absolute inset-x-0 top-0 h-[200px] bg-gray-100 flex items-center justify-center overflow-hidden">
            {imageUrl ? (
              <img 
                key={imageUrl}
                src={imageUrl}
                alt={title}
                className="w-full h-full object-cover"
              />
            ) : (
              <svg className="h-12 w-12 text-gray-300" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 16l4.586-4.586a2 2 0 012.828 0L16 16m-2-2l1.586-1.586a2 2 0 012.828 0L20 14m-6-6h.01M6 20h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z" />
              </svg>
            )}
            <div className="absolute inset-0 bg-gradient-to-b from-transparent via-black/50 to-black/80"></div>
          </div>
          <div className="relative h-full flex flex-col" ref={contentRef} style={{overflow:'hidden'}}>
            <div className={`flex justify-between items-center px-4 z-30 relative`}>
              {renderTopLeftContent()}
              <button 
                onClick={() => {
                  if (languages.length > 1) {
                    toggleLanguageModal();
                  }
                }} 
                className="bg-indigo-600 rounded-full mt-2 w-10 h-10 items-center justify-center shadow-lg border border-white text-white"
              >
                {selectedLang?.toUpperCase()}
              </button>
            </div>

            <div className="mt-auto px-4 pt-10 h-24 flex flex-col justify-end">
              <h2 
                className={`text-2xl font-bold truncate min-h-[2rem] transition-all duration-1000 text-white`}
              >
                {title}
              </h2>
              <p 
                className={`text-lg truncate min-h-[1.5rem] transition-all duration-1000 text-gray-200`}
              >
                {subtitle}
              </p>
            </div>

            <div className="bg-custom-gradient h-full pt-6 px-4 mt-3 flex flex-col">
              <h3 className="text-xl font-semibold text-white mb-4">
                {{
                  'ko': '주요 포인트', 
                  'en': 'Main Points',
                  'zh': '主要要点',
                  'ja': '主要ポイント'
                }[selectedLang]}
              </h3>
              <div className="flex-1 overflow-y-auto scrollbar-hidden">
                <ul className="space-y-4">
                  {guideData?.points?.map((point, index) => (
                    <div
                      key={point._id}
                      className="mb-4 bg-white rounded-lg px-2 py-3 flex items-center"
                      style={{ cursor: 'pointer' }}
                      onClick={() => handlePointClick(point)}
                    >
                      <div className="w-10 h-10 bg-indigo-100 rounded-full flex-shrink-0 flex items-center justify-center mr-4">
                        <span className="text-lg font-bold text-indigo-600">
                          {index + 1}
                        </span>
                      </div>
                      <div className="flex-1 min-w-0">
                        <span className="text-lg font-bold mb-1 text-gray-800 block truncate">
                          {point[`name_${selectedLang}`] || point.name}
                        </span>
                      </div>
                      <ChevronRight size={24} color="#4f46e5" className="flex-shrink-0" />
                    </div>
                  ))}
                </ul>
              </div>
            </div>

            <div 
              ref={bottomSheetRef}
              className={`absolute inset-x-0 bg-white transition-all duration-300 ease-in-out ${
                isDragging ? 'cursor-grabbing' : 'cursor-grab'
              } `}
              style={{
                height: '100%',
                zIndex: 10,
                touchAction: 'none',
                transform: bottomSheetOpen ? 'translateY(0)' : 'translateY(100%)'
              }}
              onTouchStart={handleTouchStart}
              onTouchMove={handleTouchMove}
              onTouchEnd={handleTouchEnd}
              onMouseDown={handleMouseDown}
            >
                <div className="w-16 h-1 bg-gray-300 rounded-full mx-auto mt-2"></div>
                <div className='relative h-full mx-4 mt-10 '>
                  <div className="flex justify-between items-center mb-1">
                  <h3 className="text-xl font-bold">
                    {selectedPoint?.[`name_${selectedLang}`] || selectedPoint?.name}
                  </h3>
                </div>
                <div className={`w-full pb-60 overflow-y-auto ${isDragging ? 'pointer-events-none' : ''}`} style={{ maxHeight: 'calc(100% - 2rem)' }}>
                  {selectedPoint?.images && selectedPoint.images.length > 0 && (
                    <div className="image-carousel">
                      <ImageCarousel 
                        images={selectedPoint.images} 
                        onImageClick={handleImageClick}
                      />
                    </div>
                  )}
                  <p className="text-gray-600">
                    {selectedPoint?.[`description_${selectedLang}`] || selectedPoint?.description}
                  </p>
                </div>
              </div>
              
            </div>
            {bottomSheetOpen && (
              <MiniAudioPlayer
                audioData={selectedPoint}
                selectedLang={selectedLang}
                isPlaying={isPlaying}
                progress={progress}
                onPlayPause={handlePlayPause}
                onClose={closeBottomSheet}
                isSpeechSynthesisSupported={isSpeechSynthesisSupported}
                isView={true}
              />
            )}
          </div>

          {selectedImage && (
            <ImageModal
              image={selectedImage}
              onClose={() => setSelectedImage(null)}
            />
          )}

          {showLanguageModal && (
            <div className="absolute top-12 right-4 bg-white p-2 z-20">
              {languages.map((lang) => (
                <button
                  key={lang.code}
                  onClick={() => handleLanguageSelect(lang.code)}
                  className={`block w-full text-left px-4 py-2 text-sm ${
                    selectedLang === lang.code ? 'bg-indigo-100 text-indigo-800' : 'hover:bg-gray-100'
                  }`}
                >
                  {lang.name}
                </button>
              ))}
            </div>
          )}
        </div>
        </div>
        <div className="bg-indigo-600 text-white text-center py-2 text-sm">
        <Link 
          to="/" 
          className="text-yellow-300 font-bold hover:text-yellow-100 transition-colors duration-300 border-b border-yellow-300 hover:border-yellow-100"
        >
          Taphere
        </Link>
        <span className="ml-1">이용 중</span>
      </div>
      </div>
    </div>
  );
};

export default GuideViewScreen;