import React from 'react';
import { useTranslation } from 'react-i18next';

// 국가별 가격 정보
const priceConfig = {
  KR: { currency: 'KRW', proPrice: 29900, symbol: '₩' },
  US: { currency: 'USD', proPrice: 29.99, symbol: '$' },
  JP: { currency: 'JPY', proPrice: 3300, symbol: '¥' },
  CN: { currency: 'CNY', proPrice: 199, symbol: '¥' },
};

function PricingSection() {
  const { t, i18n } = useTranslation();

  // 현재 언어에 따라 국가 결정 (실제로는 사용자의 위치나 설정에 따라 결정해야 함)
  const getCurrentCountry = () => {
    switch(i18n.language) {
      case 'ko': return 'KR';
      case 'en': return 'US';
      case 'ja': return 'JP';
      case 'zh': return 'CN';
      default: return 'US';
    }
  };

  const country = getCurrentCountry();
  const { currency, proPrice, symbol } = priceConfig[country];

  const formatPrice = (price) => {
    return new Intl.NumberFormat(i18n.language, { style: 'currency', currency: currency }).format(price);
  };

  return (
    <section id="pricing" className="mb-12 sm:mb-20">
      <h2 className="text-3xl sm:text-4xl font-bold mb-6 sm:mb-8 text-center">{t('home.pricing.title')}</h2>
      <div className="bg-white p-6 sm:p-8 rounded-lg shadow-lg">
        <h3 className="text-xl sm:text-2xl font-semibold mb-4 sm:mb-6 text-center">{t('home.pricing.subtitle')}</h3>
        <p className="text-gray-600 mb-6 text-center">{t('home.pricing.description')}</p>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 sm:gap-8">
          <div className="border border-indigo-200 p-4 sm:p-6 rounded-lg text-center flex flex-col h-full">
            <div className="flex-grow">
              <h4 className="text-lg sm:text-xl font-semibold mb-3 sm:mb-4">{t('home.pricing.free.name')}</h4>
              <p className="text-2xl sm:text-3xl font-bold text-indigo-600 mb-2">{t('home.pricing.free.price')}</p>
              <ul className="text-sm text-gray-600 mt-4 space-y-2">
                <li>{t('home.pricing.free.features.guides', { count: 3 })}</li>
                <li>{t('home.pricing.free.features.points', { count: 10 })}</li>
                <li>{t('home.pricing.free.features.public')}</li>
                <li>{t('home.pricing.free.features.basicAnalytics')}</li>
              </ul>
            </div>
            <button className="mt-6 bg-indigo-600 text-white py-2 px-4 rounded-lg hover:bg-indigo-700 transition duration-300 w-full">
              {t('home.pricing.startButton.free')}
            </button>
          </div>
          <div className="border border-indigo-200 p-4 sm:p-6 rounded-lg text-center flex flex-col h-full">
            <div className="flex-grow">
              <h4 className="text-lg sm:text-xl font-semibold mb-3 sm:mb-4">{t('home.pricing.pro.name')}</h4>
              <p className="text-2xl sm:text-3xl font-bold text-indigo-600 mb-2">
                {formatPrice(proPrice)}<span className="text-sm text-gray-600">{t('home.pricing.pro.period')}</span>
              </p>
              <ul className="text-sm text-gray-600 mt-4 space-y-2">
                <li>{t('home.pricing.pro.features.guides', { count: 10 })}</li>
                <li>{t('home.pricing.pro.features.points', { count: 30 })}</li>
                <li>{t('home.pricing.pro.features.private')}</li>
                <li>{t('home.pricing.pro.features.advancedAnalytics')}</li>
                <li>{t('home.pricing.pro.features.support')}</li>
              </ul>
            </div>
            <button className="mt-6 bg-indigo-600 text-white py-2 px-4 rounded-lg hover:bg-indigo-700 transition duration-300 w-full">
              {t('home.pricing.startButton.pro')}
            </button>
          </div>
          <div className="border border-indigo-200 p-4 sm:p-6 rounded-lg text-center flex flex-col h-full">
            <div className="flex-grow">
              <h4 className="text-lg sm:text-xl font-semibold mb-3 sm:mb-4">{t('home.pricing.enterprise.name')}</h4>
              <p className="text-lg sm:text-xl font-bold text-indigo-600 mb-2">{t('home.pricing.enterprise.price')}</p>
              <ul className="text-sm text-gray-600 mt-4 space-y-2">
                <li>{t('home.pricing.enterprise.features.customGuides')}</li>
                <li>{t('home.pricing.enterprise.features.customPoints')}</li>
                <li>{t('home.pricing.enterprise.features.customAnalytics')}</li>
                <li>{t('home.pricing.enterprise.features.dedicatedSupport')}</li>
                <li>{t('home.pricing.enterprise.features.customIntegration')}</li>
              </ul>
            </div>
            <a 
              href="mailto:enterprise@taphere.com?subject=Enterprise%20Plan%20Inquiry"
              className="mt-6 bg-indigo-600 text-white py-2 px-4 rounded-lg hover:bg-indigo-700 transition duration-300 inline-block w-full"
            >
              {t('home.pricing.startButton.enterprise')}
            </a>
          </div>
        </div>
      </div>
    </section>
  );
}

export default PricingSection;