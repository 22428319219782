import React, { useState, useEffect, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy, faLock, faEye, faEyeSlash, faQrcode, faWifi, faShareAlt, faDownload, faExclamationTriangle, faCheckCircle, faShoppingCart, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import QRCode from 'qrcode.react';
import { useTranslation } from 'react-i18next';

const ShareModal = ({ shareType, isOpen, onClose, shareCode, guideId, pointId, isPrivate, password, initialTab }) => {
  const { t, i18n } = useTranslation();
  const [showPassword, setShowPassword] = useState(false);
  const [isClosing, setIsClosing] = useState(false);
  const [activeTab, setActiveTab] = useState(initialTab || 'qr');
  const [nfcStatus, setNfcStatus] = useState('unavailable'); // 'unavailable', 'available', 'writing'
  const qrRef = useRef(null);

  const Url = `${window.location.origin}/view?guide_id=${guideId || ''}&point_id=${pointId || ''}&share_code=${shareCode || ''}`;

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden';
      setActiveTab(initialTab || 'qr');
    }
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, [isOpen, initialTab]);

  useEffect(() => {
    // Check if NFC is available
    if ('NDEFReader' in window) {
      setNfcStatus('available');
    }
  }, []);

  const copyShareCode = () => {
    navigator.clipboard.writeText(shareCode).then(() => {
      alert(t('shareModal.shareCodeCopied'));
    }).catch(err => {
      console.error(t('shareModal.shareCodeCopyFailed'), err);
    });
  };

  const copyUrl = () => {
    navigator.clipboard.writeText(Url).then(() => {
      alert(t('shareModal.urlCopied'));
    }).catch(err => {
      console.error(t('shareModal.urlCopyFailed'), err);
    });
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleClose = () => {
    setIsClosing(true);
    setTimeout(() => {
      setIsClosing(false);
      onClose();
    }, 300);
  };

  const handleBackgroundClick = (e) => {
    if (e.target === e.currentTarget) {
      handleClose();
    }
  };

  const downloadQRCode = () => {
    const canvas = qrRef.current.querySelector('canvas');
    const image = canvas.toDataURL("image/png").replace("image/png", "image/octet-stream");
    const link = document.createElement('a');
    link.href = image;
    link.download = 'qr-code.png';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const writeNFC = async () => {
    if (nfcStatus !== 'available') return;

    setNfcStatus('writing');
    try {
      const ndef = new window.NDEFReader();
      await ndef.write({
        records: [{ recordType: "url", data: Url+'&by=nfc' }]
      });
      alert(t('shareModal.nfcWriteSuccess'));
    } catch (error) {
      console.error("Error writing NFC: ", error);
      alert(t('shareModal.nfcWriteFailed'));
    } finally {
      setNfcStatus('available');
    }
  };

  if (!isOpen && !isClosing) return null;

  // const isGuide = shareType === 'guide';
  const isGuide = pointId ? false : true;
  const titleKey = isGuide ? 'shareModal.guideTitle' : 'shareModal.pointTitle';
  const descriptionKey = isGuide ? 'shareModal.guideDescription' : 'shareModal.pointDescription';

  const renderTabContent = () => {
    switch(activeTab) {
      case 'qr':
        return (
          <div className="mt-4 flex flex-col items-center" ref={qrRef}>
            <QRCode value={Url+'&by=qr'} size={128} />
            <div className="mt-2 space-x-2">
              <button onClick={copyUrl} className="bg-indigo-600 text-white px-3 py-1 rounded-md hover:bg-indigo-700 transition duration-300">
                <FontAwesomeIcon icon={faCopy} /> {t('shareModal.copyUrl')}
              </button>
              <button onClick={downloadQRCode} className="bg-green-600 text-white px-3 py-1 rounded-md hover:bg-green-700 transition duration-300">
                <FontAwesomeIcon icon={faDownload} /> {t('shareModal.downloadQR')}
              </button>
            </div>
          </div>
        );
      case 'nfc':
        return (
          <div className="mt-4 text-center">
            <div className="mb-4">
              {nfcStatus === 'available' && (
                <div className="text-green-600">
                  <FontAwesomeIcon icon={faCheckCircle} className="text-3xl mb-2" />
                  <p>{t('shareModal.nfcAvailable')}</p>
                </div>
              )}
            </div>
            <div className="flex justify-center items-center mb-4">
              <span className="text-sm mr-2">{t('shareModal.nfcUrl')}:</span>
              <button 
                onClick={copyUrl} 
                className="px-3 py-1 text-sm rounded-md bg-indigo-600 text-white hover:bg-indigo-700 transition duration-300 flex items-center"
              >
                <FontAwesomeIcon icon={faCopy} className="mr-1" />
                {t('shareModal.copyNfcUrl')}
              </button>
            </div>
            {nfcStatus === 'available' && (
              <button 
                onClick={writeNFC} 
                className="w-full px-4 py-2 rounded-md bg-indigo-600 text-white hover:bg-indigo-700 transition duration-300"
              >
                <FontAwesomeIcon icon={faWifi} className="mr-2" />
                {t('shareModal.writeNFC')}
              </button>
            )}
            <div className="mt-6 mb-6 p-4 bg-blue-100 rounded-md border border-blue-300">
              <p className="text-blue-800 font-medium flex items-start">
                <FontAwesomeIcon icon={faInfoCircle} className="mr-2 mt-1" />
                <span>{t('shareModal.nfcWriteInstructions')}</span>
              </p>
            </div>
            {nfcStatus === 'writing' && (
              <div className="mt-4 text-blue-600">
                <FontAwesomeIcon icon={faWifi} className="text-3xl mb-2 animate-pulse" />
                <p>{t('shareModal.nfcWriting')}</p>
              </div>
            )}
            <div className="mt-4 bg-gray-100 p-4 rounded-md">
              <h4 className="font-bold mb-2">{t('shareModal.nfcTagRequest')}:</h4>
              <p className="text-sm mb-2">{t('shareModal.nfcTagRequestDesc')}</p>
              <ol className="list-decimal list-inside text-left text-sm">
                <li>{t('shareModal.nfcStep1')}</li>
                <li>{t('shareModal.nfcStep2')}</li>
                <li>{t('shareModal.nfcStep3')}</li>
                <li>{t('shareModal.nfcStep4')}</li>
              </ol>
            </div>
            <div className="mt-4 text-sm">
              <p>{t('shareModal.nfcContact')}</p>
              <p className="font-bold">{t('shareModal.nfcEmail')}: support@taphere.app</p>
              <p className="font-bold">
                {t('shareModal.nfcPhone')}: {i18n.language === 'ko' ? '062-228-0888' : '+82 (062) 228-0888'}
              </p>
            </div>
          </div>
        );
      case 'code':
        return (
          <div className="mt-4 flex flex-col items-center">
            <span className="text-2xl font-bold tracking-widest">{shareCode}</span>
            <button onClick={copyShareCode} className="mt-2 bg-indigo-600 text-white px-3 py-1 rounded-md hover:bg-indigo-700 transition duration-300">
              <FontAwesomeIcon icon={faCopy} /> {t('shareModal.copyShareCode')}
            </button>
            {isPrivate && (
              <div className="mt-4 text-center">
                <p className="text-yellow-600"><FontAwesomeIcon icon={faLock} className="mr-2" />{t('shareModal.privateGuideInfo')}</p>
                <div className="mt-2 flex items-center justify-center">
                  <span className="mr-2">{t('shareModal.password')}:</span>
                  <div className="relative">
                    <input
                      type={showPassword ? "text" : "password"}
                      value={password}
                      readOnly
                      className="pr-10 border rounded px-2 py-1"
                    />
                    <button
                      onClick={togglePasswordVisibility}
                      className="absolute inset-y-0 right-0 pr-3 flex items-center"
                    >
                      <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
                    </button>
                  </div>
                </div>
              </div>
            )}
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <div 
      className={`fixed z-10 inset-0 overflow-y-auto ${isClosing ? 'animate-fadeOut' : 'animate-fadeIn'}`}
      aria-labelledby="modal-title" 
      role="dialog" 
      aria-modal="true"
      onClick={handleBackgroundClick}
    >
      <div className="flex items-center justify-center min-h-screen px-4 pt-4 pb-20 text-center sm:block sm:p-0">
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true"></div>
        <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
        <div className={`inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg w-full ${isClosing ? 'animate-modalOut' : 'animate-modalIn'}`}>
          <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
            <div className="sm:flex sm:items-start">
              <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left w-full">
                <h3 className="text-lg leading-6 font-medium text-gray-900" id="modal-title">
                  {t(titleKey)}
                </h3>
                <div className="mt-2">
                  <p className="text-sm text-gray-500">
                    {t(descriptionKey)}
                  </p>
                </div>
                <div className="mt-4 flex justify-center space-x-4">
                  <button 
                    onClick={() => setActiveTab('qr')} 
                    className={`px-3 py-2 rounded-md ${activeTab === 'qr' ? 'bg-indigo-600 text-white' : 'bg-gray-200 text-gray-700'}`}
                  >
                    <FontAwesomeIcon icon={faQrcode} /> <span className="hidden sm:inline">{t('shareModal.qrCode')}</span>
                  </button>
                  <button 
                    onClick={() => setActiveTab('nfc')} 
                    className={`px-3 py-2 rounded-md ${activeTab === 'nfc' ? 'bg-indigo-600 text-white' : 'bg-gray-200 text-gray-700'}`}
                  >
                    <FontAwesomeIcon icon={faWifi} /> <span className="hidden sm:inline">{t('shareModal.nfc')}</span>
                  </button>
                  {isGuide && <button 
                    onClick={() => setActiveTab('code')} 
                    className={`px-3 py-2 rounded-md ${activeTab === 'code' ? 'bg-indigo-600 text-white' : 'bg-gray-200 text-gray-700'}`}
                  >
                    <FontAwesomeIcon icon={faShareAlt} /> <span className="hidden sm:inline">{t('shareModal.shareCode')}</span>
                  </button>}
                </div>
                {renderTabContent()}
              </div>
            </div>
          </div>
          <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
            <button
              type="button"
              className="w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
              onClick={handleClose}
            >
              {t('shareModal.close')}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShareModal;