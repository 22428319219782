import React from 'react';
import { useTranslation } from 'react-i18next';

function BenefitsSection() {
  const { t } = useTranslation();

  const benefits = [
    { icon: '&#128100;', title: t('home.benefits.personalExperience.title'), description: t('home.benefits.personalExperience.description') },
    { icon: '&#127760;', title: t('home.benefits.multiLanguage.title'), description: t('home.benefits.multiLanguage.description') },
    { icon: '&#128184;', title: t('home.benefits.costReduction.title'), description: t('home.benefits.costReduction.description') },
    { icon: '&#128202;', title: t('home.benefits.dataInsights.title'), description: t('home.benefits.dataInsights.description') },
    { icon: '&#128241;', title: t('home.benefits.accessibility.title'), description: t('home.benefits.accessibility.description') },
    { icon: '&#127968;', title: t('home.benefits.remoteAccess.title'), description: t('home.benefits.remoteAccess.description') },
  ];

  return (
    <section id="benefits" className="mb-12 sm:mb-20">
      <h2 className="text-3xl sm:text-4xl font-bold mb-6 sm:mb-8 text-center">{t('home.benefits.title')}</h2>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 sm:gap-8">
        {benefits.map((benefit, index) => (
          <div key={index} className="bg-white p-6 sm:p-8 rounded-lg shadow-lg transform hover:scale-105 transition duration-300">
            <div className="text-4xl mb-4 text-indigo-600" dangerouslySetInnerHTML={{ __html: benefit.icon }}></div>
            <h3 className="text-xl font-semibold mb-4">{benefit.title}</h3>
            <p className="text-gray-600">{benefit.description}</p>
          </div>
        ))}
      </div>
    </section>
  );
}

export default BenefitsSection;
