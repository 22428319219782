import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

function Footer() {
  const { t } = useTranslation();

  return (
    <footer className="bg-gray-800 text-white py-8 mt-20">
      <div className="container mx-auto px-4 text-center">
        <p className="mb-4">&copy; 2024 Taphere. All rights reserved.</p>
        <nav className="flex justify-center space-x-4">
          <Link to="/terms-of-service" className="hover:text-gray-300 transition-colors duration-300">
            {t('common.footer.termsOfService')}
          </Link>
          <Link to="/privacy-policy" className="hover:text-gray-300 transition-colors duration-300">
            {t('common.footer.privacyPolicy')}
          </Link>
        </nav>
      </div>
    </footer>
  );
}

export default Footer;