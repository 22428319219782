import React from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { useTranslation } from 'react-i18next';

const TopGuidesChart = ({ data }) => {
  const { t, i18n } = useTranslation();

  // Function to get the appropriate title based on the current language
  const getLocalizedTitle = (guide) => {
    const languages = ['ko', 'zh', 'ja', 'en'];
    const currentLang = i18n.language;
    
    // First, try to get the title in the current language
    if (guide[`title_${currentLang}`]) {
      return guide[`title_${currentLang}`];
    }
    
    // If not available, try other languages in the specified order
    for (let lang of languages) {
      if (guide[`title_${lang}`]) {
        return guide[`title_${lang}`];
      }
    }
    
    // If no specific language title is found, return the 'name' field if it exists
    if (guide.name) {
      return guide.name;
    }
    
    // If all else fails, return a default message
    return t('TopGuidesChart.unavailableTitle');
  };

  // Function to get rank suffix based on the current language
  const getRankSuffix = (rank) => {
    switch (i18n.language) {
      case 'ko':
        return '위';
      case 'zh':
        return '位';
      case 'ja':
        return '位';
      default:
        return ['st', 'nd', 'rd'][rank - 1] || 'th';
    }
  };

  // Add ranking and localized names to the data
  const rankedData = data.map((item, index) => {
    const rank = index + 1;
    const localizedName = getLocalizedTitle(item);
    return {
      ...item,
      ranking: `${rank}${getRankSuffix(rank)}`,
      localizedName,
      shortName: `${rank}${getRankSuffix(rank)} ${localizedName.substring(0, 10)}...`
    };
  });

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      const data = payload[0].payload;
      return (
        <div className="bg-white p-2 border border-gray-300 rounded shadow">
          <p className="font-bold">{data.localizedName}</p>
          <p>{t('TopGuidesChart.uses')}: {data.uses}</p>
        </div>
      );
    }
    return null;
  };

  return (
    <div className="bg-white overflow-hidden shadow rounded-lg">
      <div className="p-5">
        <h3 className="text-lg font-semibold mb-4">{t('TopGuidesChart.topGuides')}</h3>
        <ResponsiveContainer width="100%" height={300}>
          <BarChart data={rankedData}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis 
              dataKey="ranking" 
              interval={0} 
              height={80}
            />
            <YAxis />
            <Tooltip content={<CustomTooltip />} />
            <Legend />
            <Bar dataKey="uses" name={t('TopGuidesChart.usageCount')} fill="#8884d8" />
          </BarChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};

export default TopGuidesChart;