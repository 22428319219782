import React, { useState } from "react";
import Layout from "@components/manage/Layout";
import { Link, Navigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { faPlus, faLightbulb, faPencilAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useGetGuidesQuery } from "../../../services/guideService";
import GuideItem from "@components/manage/guide/GuideItem";
import ShareModal from "@components/manage/ShareModal";
import Pagination from "@components/common/Pagination";
import LoadingSpinner from "@components/LoadingSpinner";

const Guides = () => {
  const { t } = useTranslation();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentGuideData, setCurrentGuideData] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const guidesPerPage = 10;

  const { data: guidesData, error, isLoading } = useGetGuidesQuery({
    page: currentPage,
    limit: guidesPerPage
  }, { refetchOnMountOrArgChange: true });

  const handleShareClick = (guideId, shareType) => {
    const guide = guidesData.docs.find(g => g._id === guideId);
    if (guide) {
      setCurrentGuideData({
        _id: guide._id,
        shareCode: guide.share_code,
        isPrivate: guide.is_private,
        password: guide.password,
        shareType: shareType
      });
      setIsModalOpen(true);
    }
  };

  const renderContent = () => {
    if (!guidesData?.docs || guidesData.docs.length === 0) {
      return (
        <div className="text-center py-10">
          <FontAwesomeIcon icon={faLightbulb} className="text-6xl text-yellow-400 mb-4" />
          <h2 className="text-2xl font-bold text-gray-800 mb-2">{t('guides.emptyState.title')}</h2>
          <p className="text-xl text-gray-600 mb-6">
            {t('guides.emptyState.description').split('\n').map((line, i) => (
              <React.Fragment key={i}>
                {line}
                <br />
              </React.Fragment>
            ))}
          </p>
          <div className="space-y-4">
            <Link
              to="/guides/new"
              className="inline-flex items-center px-6 py-3 border border-transparent text-lg font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 transition duration-150 ease-in-out"
            >
              <FontAwesomeIcon icon={faPlus} className="mr-2" />
              {t('guides.createFirst')}
            </Link>
            <p className="text-sm text-gray-500 mt-4">
              <FontAwesomeIcon icon={faPencilAlt} className="mr-2" />
              {t('guides.needIdeas')}
            </p>
            <ul className="text-sm text-gray-600 list-disc list-inside">
              <li>{t('guides.ideaList.item1')}</li>
              <li>{t('guides.ideaList.item2')}</li>
              <li>{t('guides.ideaList.item3')}</li>
            </ul>
          </div>
        </div>
      );
    }

    return (
      <>
        <ul className="divide-y divide-gray-200">
          {guidesData.docs.map(guide => (
            <GuideItem key={guide._id} guide={guide} onShareClick={handleShareClick} />
          ))}
        </ul>
        <Pagination
          currentPage={guidesData?.page}
          totalPages={guidesData?.pages}
          onPageChange={setCurrentPage}
        />
      </>
    );
  };

  if (isLoading) {
    return <LoadingSpinner isLoading={true}/>;
  }

  if (error) {
    return <Navigate to="/not-found" />;
  }

  return (
    <Layout>
      <div className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
        <div className="bg-white shadow overflow-hidden sm:rounded-md">
          <div className="px-4 py-5 border-b border-gray-200 sm:px-6 flex justify-between items-center">
            <div className="text-sm text-gray-500">
              {t('guides.totalCount', { count: guidesData?.total || 0 })}
            </div>
            
            {guidesData?.docs?.length > 0 && <Link
              to="/guides/new"
              className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              <FontAwesomeIcon icon={faPlus} className="mr-2" />
              {t('guides.createNew')}
            </Link>}
          </div>
          {renderContent()}
        </div>
      </div>
      <ShareModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        isPrivate={currentGuideData?.isPrivate}
        password={currentGuideData?.password}
        guideId={currentGuideData?._id}
        shareCode={currentGuideData?.shareCode}
        shareType={currentGuideData?.shareType}
        initialTab={currentGuideData?.shareType} // 'qr', 'nfc', 또는 'code'
      />
    </Layout>
  );
};

export default Guides;