import { useTranslation } from 'react-i18next';
import { useLocation, Link } from 'react-router-dom';
import constants from '@constants';

function Header() {
  const { t, i18n } = useTranslation();

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  }

  return (
    <header className="bg-gradient-to-r from-indigo-600 to-purple-600 text-white py-6">
      <div className="container mx-auto px-4">
        <div className="flex flex-col sm:flex-row justify-between items-center">
          <div className="text-center sm:text-left mb-6 sm:mb-0">
            <Link to="/"><h1 className="text-4xl sm:text-5xl font-bold mb-2">Taphere</h1></Link>
            <p className="text-lg sm:text-xl">{t('common.slogan')}</p>
          </div>
          <div className="flex flex-col sm:flex-row items-center space-y-4 sm:space-y-0 sm:space-x-4">
            <select className="bg-white text-gray-800 rounded px-2 py-1 w-full sm:w-auto" defaultValue={i18n.language} onChange={e=>changeLanguage(e.target.value)}>
              {constants.common.LANGUAGES.map(lang=><option key={lang.code} value={lang.code}>{lang.name}</option>)}
            </select>
          </div>
        </div>
      </div>
    </header>
  );
}

export default Header;