import React from 'react';
import { useTranslation } from 'react-i18next';

function IntroSection() {
  const { t } = useTranslation();

  return (
    <section id="intro" className="mb-12 sm:mb-20">
      <h2 className="text-3xl sm:text-4xl font-bold mb-6 sm:mb-8 text-center">{t('home.intro.title')}</h2>
      <div className="bg-white p-6 sm:p-8 rounded-lg shadow-lg">
        <p className="text-base sm:text-lg text-gray-700 leading-relaxed mb-4">
          {t('home.intro.description1')}
        </p>
        <p className="text-base sm:text-lg text-gray-700 leading-relaxed mb-4">
          {t('home.intro.description2')}
        </p>
        <p className="text-base sm:text-lg text-gray-700 leading-relaxed">
          {t('home.intro.description3')}
        </p>
      </div>
    </section>
  );
}

export default IntroSection;

