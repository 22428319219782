import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import '@css/index.css';
import '@css/global.css';
import App from './App';
import { Provider } from 'react-redux';
import store from '@store';
import { SidebarProvider } from '@contexts/SidebarContext';
import { GoogleOAuthProvider } from '@react-oauth/google';
import ScrollToTop from '@components/ScrollToTop';

// const root = ReactDOM.createRoot(document.body);

// const noscript = document.createElement('noscript');
// noscript.textContent = 'You need to enable JavaScript to run this app.';
// document.body.prepend(noscript);

// root.render(
//   <React.StrictMode>
//     <BrowserRouter>
//       <Provider store={store}>
//         <SidebarProvider>
//           <App />
//         </SidebarProvider>
//       </Provider>
//     </BrowserRouter>
//   </React.StrictMode>
// );
const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
    <GoogleOAuthProvider clientId="919241956214-5at4a9qvpv5i52oh70g4rtbtst8dk2kq.apps.googleusercontent.com">
      <BrowserRouter>
        <ScrollToTop />
        <Provider store={store}>
          <SidebarProvider>
            <App />
          </SidebarProvider>
        </Provider>
      </BrowserRouter>
    </GoogleOAuthProvider>
  </React.StrictMode>
);