import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Play, Pause, X, AlertTriangle } from 'lucide-react';

const MiniAudioPlayer = ({ audioData, selectedLang, isPlaying, progress, onPlayPause, onClose, isSpeechSynthesisSupported, isView = false }) => {
  const { t } = useTranslation();

  if (!isSpeechSynthesisSupported) {
    return (
      <div className={`bg-yellow-100 border-t border-gray-200 shadow-lg p-4 ${isView ? '' : 'mb-10'} z-50`}>
        <div className="flex items-center">
          <AlertTriangle className="text-yellow-500 mr-2" size={24} />
          <p className="text-sm text-yellow-700">
            {t('audioPlayer.speech_synthesis_not_supported')}
          </p>
        </div>
      </div>
    );
  }

  return (
    <div className={`bg-white border-t border-gray-200 shadow-lg p-4 ${isView ? '' : 'mb-10'} z-50`}>
      <div className="flex items-center justify-between">
        <div className="flex-1 mr-4">
          <h3 className="text-lg font-semibold text-gray-800 truncate">
            {audioData?.[`name_${selectedLang}`]}
          </h3>
          <p className="text-sm text-gray-500">
            {isPlaying ? t('audioPlayer.now_playing') : t('audioPlayer.stopped')}
          </p>
        </div>
        <div className="flex items-center">
          <button
            onClick={onPlayPause}
            className="mr-3 bg-blue-500 text-white rounded-full p-2"
          >
            {isPlaying ? <Pause size={24} /> : <Play size={24} />}
          </button>
          <button
            onClick={onClose}
            className="bg-gray-200 rounded-full p-2"
          >
            <X size={24} color="#4B5563" />
          </button>
        </div>
      </div>
      <div className="w-full h-2 bg-gray-200 rounded-full mt-2 overflow-hidden">
        <div
          className="h-full bg-blue-500 rounded-full"
          style={{ width: `${progress}%` }}
        />
      </div>
    </div>
  );
};

export default MiniAudioPlayer;