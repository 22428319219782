import React from "react";
import Layout from "@components/manage/Layout";
import DailyUsersChart from "@components/manage/analysis/DailyUsersChart";
import TopGuidesChart from "@components/manage/analysis/TopGuidesChart";
import OverviewCard from "@components/manage/analysis/OverviewCard";
import { Navigate } from "react-router-dom";
import { useGetAnalysisDataQuery } from "@services/analysisService";
import { useTranslation } from 'react-i18next';
import LoadingSpinner from "@components/LoadingSpinner";
import Helpers from "@helpers";

const Analysis = () => {
  const { t, i18n } = useTranslation();
  const { data, error, isLoading } = useGetAnalysisDataQuery();

  const formatTopGuides = (guides) => {
    return guides.map(guide => ({
      name: guide[`title_${i18n.language}`] || guide.title_en || guide.title_ko || guide.title_zh || guide.title_ja,
      uses: guide.users
    }));
  };

  const formatDailyUsers = (users) => {
    return users.map(item => ({
      name: new Date(item.date).toLocaleDateString(i18n.language, { month: '2-digit', day: '2-digit' }),
      users: item.users
    }));
  };

  if (isLoading) {
    return <LoadingSpinner isLoading={true} />;
  }

  if (error) {
    return <Navigate to="/" />;
  }

  const { dailyUsers, topGuides, overview } = data;

  return (
    <Layout>
      <div className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8 px-4">
        <div className="grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3 mb-8">
          <OverviewCard
            icon="fa-map-marked-alt"
            title={t('guides.overview.totalGuides')}
            value={overview.totalGuides}
            bgColor="bg-indigo-500"
          />
          <OverviewCard
            icon="fa-users"
            title={t('guides.overview.usersThisMonth')}
            value={overview.usersThisMonth}
            bgColor="bg-green-500"
          />
          <OverviewCard
            icon="fa-headphones"
            title={t('guides.overview.totalPlayingTime')}
            value={Helpers.formatTime(overview.usageTime, i18n.language)}
            bgColor="bg-yellow-500"
          />
        </div>
        
        <div className="grid grid-cols-1 gap-5 lg:grid-cols-2">
          <div className="w-full overflow-x-auto">
            <DailyUsersChart data={formatDailyUsers(dailyUsers)} />
          </div>
          <div className="w-full overflow-x-auto">
            <TopGuidesChart data={formatTopGuides(topGuides)} />
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Analysis;