import { setCredentials, setLogout, setUser } from "../store/slice/authSlice";
import { apiService } from "./api";

export const authApi = apiService.injectEndpoints({
  endpoints: builder => ({
    signup: builder.mutation({
      query: (credentials) => {
        return {
          url: '/auth/signup',
          method: 'POST',
          body: credentials,
        };
      },
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(setCredentials(data));
        } catch (error) {
          console.error('signup error', error);
        }
      },
    }),
    login: builder.mutation({
      query: (credentials) => {
        return {
          url: '/auth/authenticate',
          method: 'POST',
          body: credentials,
        };
      },
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          const response = await queryFulfilled;
          dispatch(setCredentials(response.data));
        } catch (error) {
          console.error('login error', error);
        }
      },
    }),
    logout: builder.mutation({
      query: () => {
        return {
          url: '/auth/logout',
          method: 'POST'
        };
      },
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          const response = await queryFulfilled;
          dispatch(setLogout());
        } catch (error) {
          console.error('logout error', error);
        }
      },
    }),
    refreshToken: builder.mutation({
      query: () => ({
        url: '/auth/refresh-token',
        method: 'POST',
      }),
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(setCredentials(data));
        } catch (error) {
          // console.log('refreshToken error', error);
          // throw error;
        }
      },
    }),
    verifyEmailRequest: builder.mutation({
      query: body => ({
        url: '/auth/verify-email-token',
        method: 'POST',
        body: body,
      }),
    }),
    verifyEmail: builder.mutation({
      query: body => ({
        url: `/auth/verify-email`,
        method: 'POST',
        body: body,
      }),
      // async onQueryStarted(arg, { dispatch, queryFulfilled }) {
      //   try {
      //     const { data } = await queryFulfilled;
      //     dispatch(setUser(data));
      //   } catch (error) {
      //     console.error('verify-email error', error);
      //   }
      // },
    }),
    passwordResetRequest: builder.mutation({
      query: email => ({
        url: '/auth/password-reset-request',
        method: 'POST',
        body: { email },
      }),
    }),
    passwordReset: builder.mutation({
      query: (token, password) => ({
        url: `/auth/password-reset/${token}`,
        method: 'POST',
        body: { password },
      }),
    }),
    me: builder.query({
      query: () => ({
        url: '/auth/me',
        method: 'GET',
      }),
      // 사용자 정보 캐싱
      providesTags: ['User'],
    }),
  }),
});

export const {
  useSignupMutation,
  useLoginMutation,
  useLogoutMutation,
  useRefreshTokenMutation,
  useMeQuery,
  useVerifyEmailRequestMutation,
  useVerifyEmailMutation,
  usePasswordResetRequestMutation,
  usePasswordResetMutation,
} = authApi;