import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Layout from "@components/manage/Layout";
import { loadTossPayments } from "@tosspayments/tosspayments-sdk";
import Alert from '../../components/common/Alert';
import { InfoIcon } from 'lucide-react';

const clientKey = 'test_gck_docs_Ovk5rk1EwkEbP0W43n07xlzm';
const customerKey = 'User_123-ABC@example.com';

const priceConfig = {
  KR: { currency: 'KRW', monthlyPrice: 29900, symbol: '₩' },
  US: { currency: 'USD', monthlyPrice: 29.99, symbol: '$' },
  JP: { currency: 'JPY', monthlyPrice: 3300, symbol: '¥' },
  CN: { currency: 'CNY', monthlyPrice: 199, symbol: '¥' },
};

function PaymentPage() {
  const navigate = useNavigate();

  const { t, i18n } = useTranslation();
  const [billingCycle, setBillingCycle] = useState('annual');
  const [isSubscribed, setIsSubscribed] = useState(false);
  const [currentPlan, setCurrentPlan] = useState(null);

  const [paymentWidget, setPaymentWidget] = useState(null);
  const [readyPayment, setReadyPayment] = useState(false);

  const getCurrentCountry = () => {
    switch(i18n.language) {
      case 'ko': return 'KR';
      case 'en': return 'US';
      case 'ja': return 'JP';
      case 'zh': return 'CN';
      default: return 'US';
    }
  };

  const country = getCurrentCountry();
  const { currency, monthlyPrice, symbol } = priceConfig[country];
  const annualPrice = monthlyPrice * 12 * 0.8;

  useEffect(() => {
    setIsSubscribed(false);
    setCurrentPlan(null);

    if(paymentWidget === null) return;

    paymentWidget.setAmount({
      currency: currency,
      value: billingCycle === 'annual' ? annualPrice : monthlyPrice,
    });
  }, [billingCycle]);

  useEffect(() => {
    async function fetchPaymentWidgets() {
      try {
        const tossPayments = await loadTossPayments(clientKey);

        const widgets = tossPayments.widgets({
          customerKey,
        });

        // 비회원 결제
        // const widgets = tossPayments.widgets({ customerKey: ANONYMOUS });

        setPaymentWidget(widgets);
      } catch (error) {
        console.error("Error fetching payment widget:", error);
      }
    }

    fetchPaymentWidgets();
  }, [clientKey, customerKey]);
    

  useEffect(() => {
    async function renderPaymentWidgets(){
      if(paymentWidget === null) return;
      
      await paymentWidget.setAmount({
        currency: currency,
        value: billingCycle === 'annual' ? annualPrice : monthlyPrice,
      });

      await Promise.all([
        paymentWidget.renderPaymentMethods({
          selector: '#payment-method',
          variantKey: 'DEFAULT'
        }),
        paymentWidget.renderAgreement({
          selector: "#agreement",
          variantKey: "AGREEMENT"
        }),
      ])

      setReadyPayment(true);
    }

    renderPaymentWidgets();
  }, [paymentWidget]);

  const formatPrice = (price) => {
    return new Intl.NumberFormat(i18n.language, { style: 'currency', currency: currency }).format(price);
  };

  const handleProceed = async () => {
    await paymentWidget.requestPayment({
      orderId: `ORD-${Date.now()}`,
      orderName: t('payment.proPlan'),
      successUrl: `${window.location.origin}/payment/success`,
      failUrl: `${window.location.origin}/fail`,
      customerEmail: 'customer@example.com',
      customerName: 'Customer',
    });
  };

  return (
    <Layout>
      <div className="max-w-2xl mx-auto py-8 px-4 sm:px-6 lg:px-8">
        {/* <Alert type="info" icon={InfoIcon}>
          {t('payment.testEnvironmentNotice', 'Test Environment - No actual charges will be made.')}
        </Alert> */}
        <div className="bg-white shadow-lg rounded-lg overflow-hidden">
          <div className="px-6 py-8">
            {/* <h1 className="text-2xl font-bold text-gray-900 mb-6">
              {t('payment.title')}
            </h1> */}
            <h2 className="text-xl font-semibold mb-4">{t('payment.proPlan')}</h2>
            <div className="flex justify-center space-x-4 mb-8">
              <button
                className={`px-6 py-2 rounded-full text-sm font-medium transition-colors duration-200 ${
                  billingCycle === 'monthly' 
                    ? 'bg-indigo-600 text-white' 
                    : 'bg-gray-200 text-gray-700 hover:bg-gray-300'
                }`}
                onClick={() => setBillingCycle('monthly')}
              >
                {t('payment.monthly')}
              </button>
              <button
                className={`px-6 py-2 rounded-full text-sm font-medium transition-colors duration-200 ${
                  billingCycle === 'annual' 
                    ? 'bg-indigo-600 text-white' 
                    : 'bg-gray-200 text-gray-700 hover:bg-gray-300'
                }`}
                onClick={() => setBillingCycle('annual')}
              >
                {t('payment.annual')}
              </button>
            </div>
            <div className="text-center mb-8">
              <p className="text-4xl font-bold text-gray-900">
                {billingCycle === 'annual' 
                  ? t('payment.annualPrice', { price: formatPrice(annualPrice) })
                  : t('payment.monthlyPrice', { price: formatPrice(monthlyPrice) })
                }
              </p>
              {billingCycle === 'annual' && (
                <p className="text-indigo-600 font-medium mt-2">
                  {t('payment.annualDiscount')}
                </p>
              )}
            </div>
            <div id="payment-method" className="mb-6"></div>
            <div id="agreement" className="mb-6"></div>
            {readyPayment && <button
              onClick={handleProceed}
              className="w-full bg-indigo-600 text-white py-3 px-4 rounded-lg hover:bg-indigo-700 transition duration-300 font-medium"
            >
              {isSubscribed 
                ? (billingCycle === currentPlan
                    ? t('payment.keepCurrentPlan')
                    : t('payment.changeToPlan', { plan: t(`payment.${billingCycle}`) }))
                : t('payment.startSubscription')}
            </button>}
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default PaymentPage;