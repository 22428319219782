import React from 'react';
import Header from '../components/home/Header';
import Footer from '../components/common/Footer';
import Navigation from '../components/home/Navigation';
import IntroSection from '../components/home/IntroSection';
import BenefitsSection from '../components/home/BenefitsSection';
import ReviewsSection from '../components/home/ReviewsSection';
import PricingSection from '../components/home/PricingSection';
import DownloadSection from '../components/home/DownloadSection';
import LanguageSupportSection from '../components/home/LanguageSupportSection';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

function Home() {
  const { t } = useTranslation();

  return (
    <div className="font-sans text-gray-800 bg-gray-100">
      <Header />
      <Navigation />
      <main className="container mx-auto px-4 py-8 sm:py-16">
        <IntroSection />
        <BenefitsSection />
        <ReviewsSection />
        <PricingSection />
        <DownloadSection />
        <LanguageSupportSection />
        <section className="text-center">
          <Link to="/login" className="inline-block bg-gradient-to-r from-indigo-600 to-purple-600 text-white py-3 px-8 rounded-full text-lg font-semibold hover:from-indigo-700 hover:to-purple-700 transition duration-300">
            {t('home.cta.start')}
          </Link>
        </section>
      </main>
      <Footer />
    </div>
  );
}

export default Home;