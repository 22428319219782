import React from 'react';
import { useTranslation } from 'react-i18next';

function ReviewsSection() {
  const { t } = useTranslation();

  return (
    <section id="reviews" className="mb-12 sm:mb-20">
      <h2 className="text-3xl sm:text-4xl font-bold mb-6 sm:mb-8 text-center">{t('home.reviews.title')}</h2>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6 sm:gap-8">
        <blockquote className="bg-white p-6 sm:p-8 rounded-lg shadow-lg">
          <p className="mb-4 text-gray-600 italic">"{t('home.reviews.review1.text')}"</p>
          <footer className="text-right font-semibold text-indigo-600">- {t('home.reviews.review1.author')}</footer>
        </blockquote>
        <blockquote className="bg-white p-6 sm:p-8 rounded-lg shadow-lg">
          <p className="mb-4 text-gray-600 italic">"{t('home.reviews.review2.text')}"</p>
          <footer className="text-right font-semibold text-indigo-600">- {t('home.reviews.review2.author')}</footer>
        </blockquote>
        <blockquote className="bg-white p-6 sm:p-8 rounded-lg shadow-lg">
          <p className="mb-4 text-gray-600 italic">"{t('home.reviews.review3.text')}"</p>
          <footer className="text-right font-semibold text-indigo-600">- {t('home.reviews.review3.author')}</footer>
        </blockquote>
        <blockquote className="bg-white p-6 sm:p-8 rounded-lg shadow-lg">
          <p className="mb-4 text-gray-600 italic">"{t('home.reviews.review4.text')}"</p>
          <footer className="text-right font-semibold text-indigo-600">- {t('home.reviews.review4.author')}</footer>
        </blockquote>
      </div>
    </section>
  );
}

export default ReviewsSection;

