import React from 'react';
import Header from './Header';
import Footer from '../common/Footer';

function Layout({ children }) {
  return (
    <>
      <Header />

      <main className="flex-grow flex items-center justify-center px-4 py-8">
        {children}
      </main>

      <Footer />
    </>
  );
}

export default Layout;