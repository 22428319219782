import React from 'react';
import { Book, Users, Headphones } from 'lucide-react';

const iconComponents = {
  'fa-map-marked-alt': Book,
  'fa-users': Users,
  'fa-headphones': Headphones,
};

const OverviewCard = ({ icon, title, value, bgColor }) => {
  const IconComponent = iconComponents[icon] || Book;

  return (
    <div className="bg-white overflow-hidden shadow rounded-lg">
      <div className="p-5">
        <div className="flex items-center">
          <div className={`flex-shrink-0 ${bgColor} rounded-md p-3`}>
            <IconComponent className="h-6 w-6 text-white" />
          </div>
          <div className="ml-5 w-0 flex-1">
            <dl>
              <dt className="text-sm font-medium text-gray-500 truncate">{title}</dt>
              <dd className="text-3xl font-semibold text-gray-900">{value}</dd>
            </dl>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OverviewCard;