import React, { useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import Header from '../components/common/Header';
import Footer from '../components/common/Footer';
import { useGetLatestTermsQuery } from '../services/termsService';
import constants from '@constants';
import LoadingSpinner from '@components/LoadingSpinner';

function TermsOfService() {
  const { t, i18n } = useTranslation();
  
  const searchParams = new URLSearchParams(window.location.search);
  
  const {
    data,
    error,
    isLoading,
    isSuccess,
    isError, 
  } = useGetLatestTermsQuery(i18n.language, {
    refetchOnMountOrArgChange: true
  });

  const refreshPage = useCallback(() => {
    window.location.reload();
  }, []);
  
  useEffect(() => {
    if (searchParams.get('lang') && constants.common.LANGUAGES.filter(lang => lang.code === searchParams.get('lang'))) {
      i18n.changeLanguage(searchParams.get('lang'));
    }    
  }, []);

  useEffect(() => {
    let timer;
    if (isLoading) {
      timer = setTimeout(() => {
        refreshPage();
      }, 1000); // 1초 후 새로고침
    }
    return () => {
      if (timer) clearTimeout(timer);
    };
  }, [isLoading, isSuccess, isError, data, error, refreshPage]);

  
  if (isLoading) {
    return <LoadingSpinner />;
  }
  
  if (error || data?.contents.length === 0) {
    return (
      <div className="font-sans text-gray-800 bg-gray-100 min-h-screen flex flex-col">
        <Header />
        <main className="flex-grow container mx-auto px-4 py-8 flex items-center justify-center">
          <div className="bg-red-100 border-l-4 border-red-500 text-red-700 p-4 max-w-lg w-full shadow-md rounded-md">
            <div className="flex items-center mb-2">
              <svg className="w-6 h-6 mr-2" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z" clipRule="evenodd" />
              </svg>
              <p className="font-bold text-lg">{t('error.termsTitle')}</p>
            </div>
            <p className="mb-2">{t('error.loadingTerms')}</p>
            <p>{t('error.tryAgainLater')}</p>
          </div>
        </main>
        <Footer />
      </div>
    );
  }
  
  // HTML 문자열에 스타일 클래스를 추가하는 함수
  const addStyleToHtml = (html) => {
    return html
      .replace(/<h1/g, '<h1 className="text-3xl font-bold mb-6 mt-8"')
      .replace(/<h2/g, '<h2 className="text-2xl font-semibold mb-4 mt-6"')
      .replace(/<h3/g, '<h3 className="text-xl font-semibold mb-3 mt-4"')
      .replace(/<p/g, '<p className="mb-4"')
      .replace(/<ul/g, '<ul className="list-disc pl-5 mb-4"')
      .replace(/<li/g, '<li className="mb-2"');
  };
  
  const styledHtml = addStyleToHtml(data?.contents?.[0].content || '');
  
  return (
    <div className="font-sans text-gray-800 bg-gray-100">
      <Header />
      <main className="container mx-auto px-4 py-8">
        <h1 className="text-4xl font-bold mb-8">{data.title}</h1>
        <div
          className="prose max-w-none"
          dangerouslySetInnerHTML={{ __html: styledHtml }}
        />
        <div className="mt-8 text-sm text-gray-600">
          <p>버전: {data.version}</p>
          <p>발효일: {new Date(data.effectiveDate).toLocaleDateString()}</p>
        </div>
      </main>
      <Footer />
    </div>
  );
}

export default TermsOfService;