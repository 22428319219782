import React from 'react';
import { useTranslation } from 'react-i18next';
import { Eye, Lock, Unlock, EyeOff, ToggleRight, ToggleLeft, Clock, MapPin } from 'lucide-react';
import Duration from '@components/common/Duration';

const GuideMetaInfo = ({ guideData }) => {
  const { t } = useTranslation();

  const renderMetaInfo = () => (
    <div className="grid grid-cols-2 lg:grid-cols-4 gap-4 text-sm">
      <MetaItem
        icon={!guideData?.is_private ? <Unlock className="w-4 h-4" /> : <Lock className="w-4 h-4" />}
        text={!guideData?.is_private ? t('guide.public') : t('guide.private')}
        color={!guideData?.is_private ? 'text-green-800' : 'text-red-800'}
        // bgcolor={!guideData?.is_private ? 'bg-green-100' : 'bg-red-100'}
      />
      <MetaItem
        icon={guideData?.active ? <ToggleRight className="w-4 h-4" /> : <ToggleLeft className="w-4 h-4" />}
        text={guideData?.active ? t('guide.active') : t('guide.inactive')}
        color={guideData?.active ? 'text-green-800' : 'text-red-800'}
        // bgcolor={guideData?.active ? 'bg-green-100' : 'bg-red-100'}
      />
      <MetaItem
        icon={<Clock className="w-4 h-4" />}
        // text={<Duration duration={guideData?.time} />}
        text={guideData?.time}
      />
      <MetaItem
        icon={<MapPin className="w-4 h-4" />}
        text={`${guideData?.points.length} ${t('guide.points')}`}
      />
    </div>
  );

  return renderMetaInfo();
};

const MetaItem = ({ icon, text, color = 'text-gray-600', bgcolor = '' }) => (
  <div className={`flex items-center justify-start ${color} rounded-full ${bgcolor}`}>
    <span className="mr-1">{icon}</span>
    <span>{text}</span>
  </div>
);

export default GuideMetaInfo;