import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { Link, replace, useNavigate } from 'react-router-dom';
import { Eye, EyeOff, Apple, Chrome } from 'lucide-react';
import Layout from '@components/account/Layout';
import { useTranslation } from 'react-i18next';
import { useLoginMutation } from '@services/authService';
import { useDispatch } from 'react-redux';
import { setCredentials } from '../../store/slice/authSlice';
import Alert from '../../components/input/Alert';
import GoogleButton from '../../components/GoogleButton';

function Login() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const [login, { isLoading }] = useLoginMutation();

  const validationSchema = Yup.object().shape({
    email: Yup.string().email(t('account.form.error.email')).required(t('account.form.error.required')),
    password: Yup.string().required(t('account.form.error.required')),
  });

  const { register, handleSubmit, setError, clearErrors, setValue, formState: { errors } } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const [showPassword, setShowPassword] = useState(false);

  const onSubmit = async (data) => {
    try {
      const result = await login({
        email: data.email,
        password: data.password,
      }).unwrap()

      i18n.changeLanguage(result?.user?.lang_code || 'en');
      
      if(result.user.is_verified_email){
        navigate('/guides', {replace: true});
      } else {
        navigate('/verify-email', {replace: true});
      }
    } catch (error) {
      console.error('Failed to login:', error);
      alert('Failed to login', JSON.stringify(error));
      setError('loginfail', {
        code: error.data?.code,
      });
    }
  };

  const handleInputChange = (e) => {
    clearErrors();
    setValue(e.target.name, e.target.value);
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <Layout>
      <div className="w-full max-w-md">
        <div className="bg-white rounded-lg shadow-md overflow-hidden p-6">
          <GoogleButton />
          <button className="w-full bg-black text-white py-2 px-4 rounded-lg hover:bg-gray-900 transition duration-300 flex items-center justify-center mb-4">
            <Apple className="w-5 h-5 mr-2" />
            {t('account.login.appleLogin')}
          </button>
          
          <p className="text-xs text-gray-500 text-center mt-2 mb-4">
            {t('account.login.termsAgreement')} <br/>
            <Link to="/terms-of-service" className="text-indigo-600 hover:text-indigo-800">{t('account.login.termsOfService')}</Link> {t('account.login.and')}&nbsp;
            <Link to="/privacy-policy" className="text-indigo-600 hover:text-indigo-800">{t('account.login.privacyPolicy')}</Link>
          </p>
          
          <div className="relative my-6">
            <hr className="border-gray-300"/>
            <span className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white px-2 text-gray-500">{t('account.login.or')}</span>
          </div>
          
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="mb-4">
              <label htmlFor="login-email" className="block text-gray-700 font-bold mb-2">{t('account.form.email')}</label>
              <input
                type="email"
                id="login-email"
                {...register('email', {
                  onChange: handleInputChange
                })}
                className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:border-indigo-500"
              />
              {errors.email && <Alert>{t(`account.form.error.${errors.email.type}`, { field: t('account.form.email') })}</Alert>}
            </div>
            <div className="mb-6">
              <label htmlFor="login-password" className="block text-gray-700 font-bold mb-2">{t('account.form.password')}</label>
              <div className="relative">
                <input
                  type={showPassword ? "text" : "password"}
                  id="login-password"
                  {...register('password', {
                    onChange: handleInputChange
                  })}
                  className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:border-indigo-500 pr-10"
                />
                <button
                  type="button"
                  onClick={togglePasswordVisibility}
                  className="absolute inset-y-0 right-0 pr-3 flex items-center text-sm leading-5"
                >
                  {showPassword ? <EyeOff className="h-5 w-5 text-gray-500" /> : <Eye className="h-5 w-5 text-gray-500" />}
                </button>
              </div>
              {errors.password && <Alert>{t(`account.form.error.${errors.password.type}`, { field: t('account.form.password') })}</Alert>}
              {errors.loginfail && <Alert>{t(`server_errors.${errors.loginfail.code}`)}</Alert>}
            </div>
            <button type="submit" className="w-full bg-indigo-600 text-white py-2 px-4 rounded-lg hover:bg-indigo-700 transition duration-300">{t('account.login.submit')}</button>
          </form>
          
          <div className="mt-4 text-center">
            <Link to="/password-reset-request" className="text-indigo-600 hover:text-indigo-800">{t('account.login.forgotPassword')}</Link>
          </div>
          
          <div className="mt-6 text-center">
            <p>{t('account.login.noAccount')} <Link to="/sign-up" className="text-indigo-600 hover:text-indigo-800 font-semibold">{t('account.signup.title')}</Link></p>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default Login;