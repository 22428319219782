import React from 'react';
import { useTranslation } from 'react-i18next';

function DownloadSection() {
  const { t } = useTranslation();

  return (
    <section id="language-support" className="mb-12 sm:mb-20">
      <h2 className="text-3xl sm:text-4xl font-bold mb-6 sm:mb-8 text-center">{t('home.languageSupport.title')}</h2>
      <div className="bg-white p-6 sm:p-8 rounded-lg shadow-lg text-center">
          <p className="text-base sm:text-lg text-gray-700 mb-6">{t('home.languageSupport.description')}</p>
          <div className="flex flex-wrap justify-center gap-4 sm:gap-8">
              <div className="text-center">
                  <span className="text-4xl">🇰🇷</span>
                  <p className="mt-2">{t('home.languageSupport.languages.ko')}</p>
              </div>
              <div className="text-center">
                  <span className="text-4xl">🇺🇸</span>
                  <p className="mt-2">{t('home.languageSupport.languages.en')}</p>
              </div>
              <div className="text-center">
                  <span className="text-4xl">🇨🇳</span>
                  <p className="mt-2">{t('home.languageSupport.languages.zh')}</p>
              </div>
              <div className="text-center">
                  <span className="text-4xl">🇯🇵</span>
                  <p className="mt-2">{t('home.languageSupport.languages.ja')}</p>
              </div>
          </div>
      </div>
    </section>
  );
}

export default DownloadSection;

