import React from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import Layout from '@components/account/Layout';
import { Link } from 'react-router-dom';
import Alert from '../../components/input/Alert';

function PasswordResetRequest() {
  const { t } = useTranslation();

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .required(t('account.passwordResetRequest.validation.emailRequired'))
      .email(t('account.passwordResetRequest.validation.emailInvalid'))
  });

  const { register, handleSubmit, formState: { errors } } = useForm({
    resolver: yupResolver(validationSchema)
  });

  const onSubmit = (data) => {
    // Here you would implement the password reset request logic
    console.log('Password reset requested for:', data.email);
  };

  return (
    <Layout>
      <div className="w-full max-w-md">
        <div className="bg-white rounded-lg shadow-md overflow-hidden p-6 mb-8">
          <h2 className="text-2xl font-bold mb-6 text-center">{t('account.passwordResetRequest.title')}</h2>
          
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="mb-4">
              <label htmlFor="reset-email" className="block text-gray-700 font-bold mb-2">{t('account.passwordResetRequest.email')}</label>
              <input
                type="email"
                id="reset-email"
                {...register('email')}
                className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:border-indigo-500"
              />
              {errors.email && <Alert>{errors.email.message}</Alert>}
            </div>
            <button
              type="submit"
              className="w-full bg-indigo-600 text-white py-2 px-4 rounded-lg hover:bg-indigo-700 transition duration-300"
            >
              {t('account.passwordResetRequest.sendResetLink')}
            </button>
          </form>
          
          <div className="mt-6 text-center">
            <p>{t('account.passwordResetRequest.rememberAccount')} <Link to="/login" className="text-indigo-600 hover:text-indigo-800 font-semibold">{t('account.passwordResetRequest.login')}</Link></p>
          </div>
        </div>
        
        <div className="bg-white rounded-lg shadow-md overflow-hidden p-6">
          <h2 className="text-2xl font-bold mb-4">{t('account.passwordResetRequest.process.title')}</h2>
          <ol className="list-decimal list-inside space-y-2 text-gray-700">
            <li>{t('account.passwordResetRequest.process.step1')}</li>
            <li>{t('account.passwordResetRequest.process.step2')}</li>
            <li>{t('account.passwordResetRequest.process.step3')}</li>
            <li>{t('account.passwordResetRequest.process.step4')}</li>
          </ol>
          <p className="mt-4 text-sm text-gray-600">
            {t('account.passwordResetRequest.process.note')}
          </p>
        </div>
      </div>
    </Layout>
  );
}

export default PasswordResetRequest;